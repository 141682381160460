  import React, { useCallback, useEffect, useState } from "react";
  import {
    Toolbar,
    Typography,
    Avatar,
    Box,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
  } from "@mui/material";

  import { RiderKycApi } from "../../../API/RiderKycApi";
  import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
  import HomeIcon from "@mui/icons-material/HomeOutlined";
  import { useNavigate } from "react-router";
  import redCall from "../../../assets/icons/red-phone.svg";
  import view from "../../../assets/icons/eye-view.svg";
  import download from "../../../assets/icons/download.svg";
  import { useLocation } from "react-router";
  import CheckCircleIcon from "@mui/icons-material/CheckCircle";
  import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";

  export default function RiderDetailKyc() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedClient, setSelectedClient] = useState<any>();
    const [clientid, setClientID] = useState<any>();
    const [mappedNumber, setMappedNumber] = useState<any>();
    const [selectedArea, setSelectedArea] = useState<any>();
    const [selectedTl, setSelectedTl] = useState<any>();
    const [merchantList, setMerchantList] = useState<any[]>([]);
    const [merchantListStore, setMerchantListStore] = useState<any[]>([]);
    const [riderDetails, setRiderDetails] = useState<any>({});
    const [error, setError] = useState<string>("");
    const [errorVerify, setErrorVerify] = useState<string>("");
    const [verifysucess, setVerifysucess] = useState<string>("");
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const [tlOptions, setTlOptions] = useState<any[]>([]);
    const merchantIdpreflied = location.state?.merchantId;
    const [isClientChanged, setIsClientChanged] = useState(false);
    const [isClientIDChanged, setIsClientIDChanged] = useState(false);
    const [isClientIDVerified, setIsClientIDVerified] = useState(false); // New state variable for Client ID verification
    const [userDetail, setUserDetail] = useState<any>({}); // New state variable for Client ID verification


    const userid = location.state?.id
    const requestId = location.state?.userid
    
    useEffect(() => {
      RiderKycApi.api?.riderDetailsKyc(userid)
        .then((response) => {
           setUserDetail(response.data);

         
        })
        .catch((error) => {
          console.error("API call error: ", error);
        });
    }, []);


    useEffect(() => {
      const fetchMerchantList = async () => {
        try {
          const response = await RiderKycApi.api.merchantListHyperTrack(requestId);
          if (response.data && response.data.length > 0) {
            setMerchantList(response.data);
          }
        } catch (error) {
          console.error("Error fetching merchant list:", error);
        }
      };
  
      fetchMerchantList();
    }, []);
    console.log('riderDetails', riderDetails)
    useEffect(() => {
     

      const fetchMerchantData = async (merchantid: number | null | undefined, userid: any) => {
        try {
          // Array to hold all promises
          const promises = [];
      
          if (merchantid) {
            // Fetch stores based on merchantId
            promises.push(RiderKycApi.api.merchantStoreListHyperTrack(merchantid));
      
            // Fetch TL options
            promises.push(fetch(`https://zypp-recommend.bcykal.com/get_tl_ids/?userId=${userid}&merchantId=${merchantid}`).then(response => response.json()));
          }
      
          // Fetch rider details
          const riderDetailsPromise = axios.get(`https://beta.bcykal.com/mobycy/v1/userDetail/userId?id=${location.state?.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          promises.push(riderDetailsPromise);
      
          // Wait for all promises to resolve
          const [storeResponse, tlData, riderResponse] = await Promise.all(promises);
      
          if (storeResponse) {
            setMerchantListStore(storeResponse.data || []);
          }
          
          if (tlData) {
            setTlOptions(tlData || []);
          }
      
          if (riderResponse) {
            setRiderDetails(riderResponse.data.data);
          }
      
          if (merchantIdpreflied) {
            setSelectedClient({
              merchantId: typeof merchantIdpreflied === 'string' ? parseInt(merchantIdpreflied, 10) : merchantIdpreflied,
              merchantName: merchantList.find((m: { merchantId: any; }) => m.merchantId === (typeof merchantIdpreflied === 'string' ? parseInt(merchantIdpreflied, 10) : merchantIdpreflied))?.merchantName,
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      

      // Ensure merchantIdpreflied is a number
        const merchantId = typeof merchantIdpreflied === 'string' ? parseInt(merchantIdpreflied, 10) : merchantIdpreflied;
        //fetchMerchantData(merchantId, location.state?.userid);
        if (location.state?.id) {
          fetchMerchantData(merchantId, location.state?.userid);
        }
    
    }, [merchantIdpreflied, location.state, merchantList]);

    const handleMerchantChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedArea(null); // Reset selected area when client changes

      setVerifysucess("");
      //setSelectedClient(newClient); 
      setIsClientIDChanged(true);  
      const selectedOption = e.target.options[e.target.selectedIndex];
      const selectedMerchantIdString = selectedOption.getAttribute("data-merchant-id");
      const selectedMerchantId = parseInt(selectedMerchantIdString || '', 10); // Convert to number
      const selectedMerchantName = selectedOption.getAttribute("data-merchant-name");
  
      setSelectedClient({
        merchantId: selectedMerchantId,
        merchantName: selectedMerchantName,
      });
      setIsClientChanged(true);
      setSelectedArea(null); 

      if (!isNaN(selectedMerchantId)) { // Ensure it's a valid number
        try {
          const storeResponse = await RiderKycApi.api.merchantStoreListHyperTrack(selectedMerchantId);
          setMerchantListStore(storeResponse.data || []);
          setSelectedArea(null); // Reset selected area when client changes

        } catch (error) {
          console.error("Error fetching stores:", error);
        }
      }
    };
    const downloadAllDocuments = () => {
      const documentIds = [
        riderDetails.aadharFront,
        riderDetails.aadharBack,
        riderDetails.pan,
        riderDetails.dl,
      ];

      documentIds.forEach((id) => {
        if (id) {
          const documentUrl = `https://dash.bcykal.com/mobycy/api/media/file/${id}`;
          const link = document.createElement("a");
          link.href = documentUrl;
          link.download = id;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    };

    function createData(name: string, id: any) {
      return { name, id };
    }

    const documents = [
      createData("Aadhar Card Front", riderDetails.aadharBack ),
      createData("Aadhar Card Back", riderDetails.aadharFront ),
      createData("Pan Card", riderDetails.pan),
      createData("Driving Licence", riderDetails.dl),
    ];

    const viewDocument = (id: any) => {
      const documentUrl = `https://dash.bcykal.com/mobycy/api/media/file/${id}`;
      setImageUrl(documentUrl);
      setImageModalOpen(true);
    };

    const downloadDocument = (id:any) => {
      const documentUrl = `https://dash.bcykal.com/mobycy/api/media/file/${id}`;
      const link = document.createElement("a");
      link.href = documentUrl;
      link.download = id;
      link.click();
    };

    const token = localStorage.getItem("access_token");
console.log('selectedArea', selectedArea)
    const assignTl = async () => {
      if (!selectedTl && location.state?.tlEnable === 1 ) {
        setError("Please select Rider’s TL");
        return;
      }
      if (!mappedNumber ) {
        setError("Please Enter Client ID Mapped Phone Number");
        return;
      }
      setError("")
      const requestData = {
        id: location.state?.id,
        clientId: clientid,
        storeId: selectedArea,
        merchantId: selectedClient?.merchantId,
        merchantName: selectedClient?.merchantName,
        tlId: selectedTl,
        phone: mappedNumber,
      };

      try {
        const response = await fetch("https://beta.bcykal.com/mobycy/v1/create/client/request/update", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        const result = await response.json();

        if (result.code === 2000) {
          setError("")
          navigate("/riderKyc");
        } else {
          setError(result.message || "Submit failed.");
        }
      } catch (error) {
        setError("An error occurred while assigning TL.");
      }
    };

    const verifyClientID = async () => {
      const userid = location.state?.userid;
      if (!clientid || !selectedClient || !userid || !selectedArea) {
        setError("Please Select Rider’s Interested Area");
        return;
      }
      setError("");

      try {
        const response = await fetch(
          `https://dash.bcykal.com/mobycy/api/v1/verify/clientId/googleSheet?clientUserId=${clientid}&merchantId=${selectedClient?.merchantId}&userId=${userid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        setErrorVerify(""); // Clear error message if verification is successful


        if (result.code === 2000) {
          setError("");
          setVerifysucess("Client ID verified successfully.");
          setIsClientIDVerified(true);  // Mark the client ID as verified
          setIsClientIDChanged(false);  // Mark Client ID as unchanged after verification
        } else {
          setError("");
          setVerifysucess("");
          console.log('selectedarea', selectedArea);
          setSelectedArea(""); // Reset selected area when client changes

          setErrorVerify(result.message || "Verification failed.");
        }
      } catch (error) {
        setErrorVerify("An error occurred while verifying Client ID.");
      }
    };
   
    const isSubmitDisabled = !isClientIDVerified || isClientIDChanged || !clientid || !selectedClient || !selectedArea;


    return (
      <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50 pl-8 pr-8">
        <div style={{ height: "40px", width: "100%" }}>
          <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            <Typography
              sx={{ flex: "1 1 75%", textAlign: "left" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <a onClick={() => navigate("/riderKyc")} className="cursor-pointer">
                <ArrowBackIcon className="mr-4 mb-1 ml-7" />
              </a>
              <b>Rider’s KYC Details</b>
            </Typography>

            <Typography
              sx={{
                flex: "1 1 100%",
                textAlign: "right",
                marginRight: "48px",
              }}
              component="div"
            >
              <HomeIcon className="mb-1" />
              {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "}
              Rider’s KYC Details
            </Typography>
          </Toolbar>
        </div>
        <div className="bg-[#fff] p-8 rounded-lg pb-4">
          <Grid container spacing={5} className="w-full mt-4 flex justify-between">
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">Rider’s Name</p>
                <TextField
                  id="outlined-basic"
                  label="Rider’s Name"
                  variant="outlined"
                  className="w-full"
                  value={userDetail?.name || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">Rider’s Mobile</p>
                <TextField
                  id="outlined-basic"
                  label="Rider’s Mobile"
                  variant="outlined"
                  className="w-full"
                  value={userDetail.mobile || ""}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" edge="end">
                          <Avatar sx={{ width: "25px", height: "25px" }} src={redCall} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">Rider’s KYC Status</p>
                <TextField
                  id="outlined-basic"
                  label="Rider’s KYC Status"
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{ shrink: true }} // stop from animating
                  value={userDetail.kycStatus ? "Verified" : "Not Verified"}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: riderDetails.kycStatus ? "#00CD5B" : "#FF725E",
                          }}
                        >
                          {riderDetails.kycStatus ? <CheckCircleIcon /> : <CancelIcon />}
                        </Typography>
                        <button
                          onClick={downloadAllDocuments}
                          style={{ marginRight: "-14px" }}
                          className="bg-[#FE5B44] px-2 py-4 text-white rounded-tl rounded-bl"
                        >
                          Download
                        </button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: riderDetails.kycStatus ? "#00CD5B" : "#FF725E",
                      },
                      "&:hover fieldset": {
                        borderColor: riderDetails.kycStatus ? "#00CD5B" : "#FF725E",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: riderDetails.kycStatus ? "#00CD5B" : "#FF725E",
                      },
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <hr className="my-7" />
          <Grid container spacing={5} className="w-full flex justify-between">
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">Rider’s Client <sup style={{color:'red'}}>*</sup></p>
                <FormControl fullWidth>
                  <select
                    id="merchant-select"
                    onChange={handleMerchantChange}
                    value={selectedClient?.merchantId || ""}
                    style={{
                      border: "1px solid #c0baba",
                      padding: 7,
                      borderRadius: 3,
                      height: 56,
                    }}
                  >
                    {merchantList.map((merchant, index) => (
                      <option
                        key={index}
                        value={merchant.merchantId}
                        data-merchant-id={merchant.merchantId}
                        data-merchant-name={merchant.merchantName}
                      >
                        {merchant.merchantName}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </Grid>
              <Grid item md={4}>
                <div className="w-full mt-4">
                  <p className="text-left mb-4 text-sm font-bold">
                    Rider’s Interested Area <sup style={{color:'red'}}>*</sup>
                  </p>
                  <FormControl fullWidth>
                    <select
                      id="city"
                      onChange={(e) => {
                        setSelectedArea(e.target.value);
                      }}
                      style={{
                        border: 1,
                        borderColor: "#c0baba",
                        borderStyle: "solid",
                        padding: 7,
                        borderRadius: 3,
                        height: 56,
                      }}
                      disabled={!merchantListStore.length}

                    >
                    <option value="" selected>Select Area</option> {/* Default option */}

                      {merchantListStore.map((merchant, index) => (
                        <option key={index} value={merchant.storeId}>
                          {merchant.storeName}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="mt-4">
                  <p className="text-left mb-4 text-sm font-bold">Client ID <sup style={{color:'red'}}>*</sup></p>
                  <TextField
                    id="outlined-basic"
                    label="Client ID"
                    variant="outlined"
                    className="w-full"
                    value={clientid}
                    onChange={(e) => {
                      setClientID(e.target.value);
                      setIsClientIDChanged(true);
                      setIsClientIDVerified(false); // Reset verification if Client ID is changed
                      setVerifysucess("");


                    }}
                    error={!!errorVerify}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">

                          <button
                            onClick={verifyClientID}
                            style={{ marginRight: "-14px" }}
                            className="bg-[#FE5B44] px-2 py-4 text-white rounded-tl rounded-bl"
                          >
                            Verify
                          </button>
                        </InputAdornment>
                      ),
                    }}
                    helperText={errorVerify}
                  />
                  <p className="text-left text-[#00cd5b]">{verifysucess}</p>
                </div>
              </Grid>
            </Grid>
            <hr className="my-7" />
            <Grid container spacing={5} className="w-full flex justify-between">
              <Grid item xs={4}>
                <div>
                  <p className="text-left mb-4 text-sm font-bold">Client ID Mapped Phone Number <sup style={{color:'red'}}>*</sup></p>
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    className="w-full"
                    type="number" // Set input type to number
      inputProps={{
        maxLength: 10, // Max length of 10 digits
        min: 0, // Minimum value (to avoid negative numbers)
        step: 1 // Step value to ensure whole numbers
      }}
      onChange={(e) => {
        const value = e.target.value;
        // Only allow numbers up to 10 digits
        if (value.length <= 10) {
          setMappedNumber(value);
        }
      }}
      value={mappedNumber}
                  />
                </div>
              </Grid>
              {location.state?.tlEnable === 2 ? null : 
              (
                <Grid className="pt-1" item xs={4}>
                  <div>
                    <p className="text-left mb-4 text-sm font-bold">Rider’s TL <sup style={{color:'red'}}>*</sup></p>
                    <FormControl fullWidth>
                      <select
                        id="city"
                        onChange={(e) => {
                          setSelectedTl(e.target.value);
                        }}
                        style={{
                          border: 1,
                          borderColor: "#c0baba",
                          borderStyle: "solid",
                          padding: 7,
                          borderRadius: 3,
                          height: 56,
                        }}
                      >
                       <option value="" selected disabled>Select TL Name</option> {/* Default option */}

                        {tlOptions.map((tl) => (
                          <option key={tl.tl_id} value={tl.tl_id}>
                            {tl.tl_name}
                          </option>
                        ))}


                      </select>
                    </FormControl>
                  </div>
                </Grid>
              )
              }
              <Grid item xs={4}>
                <div>
                <button
        onClick={assignTl}
        className={`px-12 py-4 mt-8 text-white rounded-lg w-full ${
          isSubmitDisabled ?  "bg-gray-400" : "bg-[#FE5B44]" 
        }`}
        disabled={isSubmitDisabled}
        style={{
          cursor: isSubmitDisabled ? 'not-allowed' : 'pointer',
          opacity: isSubmitDisabled ? 0.6 : 1
        }}
      >
        Submit
      </button>
                  {error && <Typography color="error">{error}</Typography>}
                </div>
              </Grid>
            </Grid>
            <div>
              <hr className="my-7" />
            </div>
            <div className="mt-3">
              <Typography
                sx={{ flex: "1 1 75%", textAlign: "left" }}
                variant="h6"
                component="div"
              >
                <b>Rider’s KYC Details</b>
              </Typography>
              <div>
                <TableContainer
                  component={Paper}
                  className="mt-3"
                  sx={{ boxShadow: "none" }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>File Name</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Actions</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documents.map((doc, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {doc.name}
                          </TableCell>
                          <TableCell align="right">
                            <div className="flex justify-end">
                              <IconButton onClick={() => viewDocument(doc.id)}>
                                <img src={view} className="mr-8" alt="view" />
                              </IconButton>
                              <IconButton
                                onClick={() => downloadDocument(doc.id)}
                              >
                                <img src={download} alt="download" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Dialog open={imageModalOpen} onClose={() => setImageModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Document Image</DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Document" style={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImageModalOpen(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
