import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import LinearProgress from "@mui/material/LinearProgress";
import MobileIotModal from "./MobileIotModal";
import { Button, Link } from "@mui/material";
import dayjs from 'dayjs'; // Import dayjs for date manipulation

const containerStyle = {
  width: "100%",
  height: "100%",
};

interface MapProps {
  selectedCityValue: string | null,
    selectedClientValue: string | null,
    selectedVehicleTypeValue: string | null,
    selectedDisconnectionValue: string | null,
    selectedOptions: string[] | null
}

const GoogleMapApi: React.FC<MapProps> = ({
  selectedCityValue,
  selectedClientValue,
  selectedVehicleTypeValue,
  selectedDisconnectionValue,
  selectedOptions
}) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true); 
  const [iotMobileLocationData, setIotMobileLocationData] = useState<any | null>(
    null
  );

  useEffect(() => {
    HyperTrackHome.api.googleMapsMobileLocation( 
      selectedCityValue,
      selectedClientValue,
      selectedVehicleTypeValue,
      selectedDisconnectionValue,
      selectedOptions)
    .then((e) => {
      setData(e.mobile_locations);
      setLoading(false);
    })

    console.log('selectedCityValue', selectedCityValue)
    
  }, [
    selectedCityValue,
    selectedClientValue,
    selectedVehicleTypeValue,
    selectedDisconnectionValue,
    selectedOptions,
  ]);

  const mid = Math.floor(data.length / 2);

  const calculateCenter = () => {
    if (data.length === 0) {
      console.error("No data available");
      return { lat: 0, lng: 0 }; // Return default center
    }

    const mid = Math.floor(data.length / 2);
    const centerMarker = data[mid];

    return {
      lat: parseFloat(centerMarker.latitude),
      lng: parseFloat(centerMarker.longitude),
    };
  };

  const center = calculateCenter();

  const fetchData = async (qrCode: string) => {
    HyperTrackHome.api.iotMobileLocation(qrCode).then((e) => {
      setIotMobileLocationData(e);
    });
  };

  console.log('testtttt---', iotMobileLocationData);

  const handleQrCodeClick = (index: number) => {
    const qrCode = data[index]?.qr_code;
    if (qrCode) {
      fetchData(qrCode);
      handleOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    // Additional cleanup or logic on modal close if needed
  };

  const getDaysDifference = (date: string) => {
    const now = dayjs();
    const updatedDate = dayjs(date);
    return now.diff(updatedDate, 'day');
  };

  const getCustomMarkerIcon = (lastUpdatedTime: string) => {
    const daysDifference = getDaysDifference(lastUpdatedTime);

    if (daysDifference === 0) {
      return 'https://zypp.app/clientdashboard-mailer/green-dot.png'; // Today
    } else if (daysDifference <= 7) {
      return 'https://zypp.app/clientdashboard-mailer/orange-dot.png'; // Within 7 days
    } else {
      return 'https://zypp.app/clientdashboard-mailer/red-dot.png'; // More than 7 days
    }
  };

  const getBackgroundColor = (connectionStatus: string) => {
    return connectionStatus === 'Connected' ? 'bg-green-500' : 'bg-red-500';
  };

  const handleMarkerClick = (index: number) => {
    setSelectedMarker(index);
  };

  console.log('mapdata-------', data);

  return (
    <>
      <div className="flex justify-between items-center pt-4 pb-4">
        <div>
          <h2 className="text-left font-bold text-base">
            Last Mobiles location and time for connected and disconnected  
          </h2>
        </div>
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="bg-[#00CB60] w-3 rounded-full h-3 mr-1"></span>
           Today
          </div>
          <div className="flex items-center ml-3  ">
            <span className="bg-[#ff8e00] w-3 rounded-full h-3 mr-1"></span>
            1-7 Days
          </div>
          <div className="flex items-center ml-3">
            <span className="bg-[#F94932] w-3 rounded-full h-3 mr-1"></span>
            More than 7 days
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-full">
          <LinearProgress
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        </div>
      ) : (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
        >
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
            {(clusterer) => (
              <>
                {data.map((item, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(item.latitude),
                      lng: parseFloat(item.longitude),
                    }}
                    icon={{
                      url: getCustomMarkerIcon(item.last_mobile_location_updated_time) || "",
                    }}
                    onClick={() => handleMarkerClick(index)}
                    clusterer={clusterer}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
          <div>
            {selectedMarker !== null && (
              <>
                <InfoWindow
                  position={{
                    lat: parseFloat(data[selectedMarker].latitude),
                    lng: parseFloat(data[selectedMarker].longitude),
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div className="flex items-center">
                    <div>
                      <p className={`p-2 text-white font-bold text-left text-slate-700 ${getBackgroundColor(data[selectedMarker].connection_status)}`}>
                        <b>{data[selectedMarker].qr_code}</b>
                      </p>
                      <p className="font-medium text-left text-slate-700 flex items-center mt-2">
                        <span className="mr-2">Name </span>
                        {data[selectedMarker].rider_name}
                      </p>
                      <p className="font-medium text-left text-slate-700 flex items-center mt-2">
                        <span className="mr-2">Mobile </span>
                        {data[selectedMarker].user_mobile}
                      </p>
                      <p className="font-medium text-left text-slate-700 flex items-center mt-2">
                        <span className="mr-2">Date/Time </span>
                        {data[selectedMarker].last_mobile_location_updated_time}
                      </p>
                      <p className="font-medium text-left text-slate-700 flex items-center mt-2">
                        <span className="mr-2">Last Lat - </span>
                        {data[selectedMarker].latitude}
                      </p>
                      <p className="font-medium text-slate-700 flex items-center mt-2">
                        <span className="mr-2">Last Long - </span>
                        {data[selectedMarker].longitude}
                      </p>
                      <p className="mt-2">
                        <b>Google Maps Link:</b>{" "}
                        <a
                          target="_blank"
                          href={`https://maps.google.com/?q=${data[selectedMarker].latitude},${data[selectedMarker].longitude}`}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          https://maps.google.com/?q={data[selectedMarker].latitude},{data[selectedMarker].longitude}
                        </a>
                      </p>
                      <div className="mt-2">
                        <Button
                          sx={{
                            bgcolor: "#00CD5B",
                            color: "#fff",
                            ml: 2,
                            mr: 2,
                            height: "30px",
                            textTransform: "none",
                            "&:hover": { bgcolor: "#00CD5B" },
                          }}
                          onClick={() => handleQrCodeClick(selectedMarker)}
                        >
                          View Mobile & Iot Location
                        </Button>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
                {iotMobileLocationData && (
                  <MobileIotModal
                    modalOpen={open}
                    qrcode={iotMobileLocationData?.data[0].qrcode}
                    iot_lat={iotMobileLocationData?.data[0].iot_lat}
                    iot_lng={iotMobileLocationData?.data[0].iot_lng}
                    mobile_lat={iotMobileLocationData?.data[0].mobile_lat}
                    mobile_lng={iotMobileLocationData?.data[0].mobile_lng}
                    iot_time={iotMobileLocationData?.data[0].iot_time}
                    mobile_time={iotMobileLocationData?.data[0].mobile_time}
                    onClose={handleClose} 
                  />
                )}
              </>
            )}
          </div>
        </GoogleMap>
      )}
    </>
  );
}

export default GoogleMapApi;
