import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CreateHubApi } from "../../API/CreateHubApi";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  py: 2,
  px: 4,
};

interface LongMenuProps {
  openFn: () => void;
  status: boolean | true;
}
const LongMenu: React.FC<LongMenuProps> = ({ openFn, status }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          color: "#A4A2A2",
          font: "Rubik",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 216,
            width: "20ch",
          },
        }}
      >
        {status === true ? (
          <MenuItem onClick={openFn}>Activate Hub</MenuItem>
        ) : (
          <MenuItem onClick={openFn}>Deactivate Hub</MenuItem>
        )}
      </Menu>
    </div>
  );
};
export default function ActionModal({ rowData }: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [statusRemark, setStatusRemark] = useState<any>();
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const createHubApi = new CreateHubApi();
    const responsebody = {
      shopId: rowData?.shopId,
      deleted: rowData?.deleted,
      statusRemark: statusRemark,
    };
    try {
      await createHubApi.hubStatusUpdate(responsebody);
      setShowSuccessSnackbar(true);
    } catch (error: any) {
      setSnackbarMessage(error.response.data.errorCode);
      setShowErrorSnackbar(true);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
    window.location.reload();
  };
  return (
    <div>
      <LongMenu openFn={handleOpen} status={rowData?.deleted} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between mb-4">
            <p className="font-bold text-base">{rowData?.shopName}</p>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex">
            <div>
              <div>
                <div className="flex border	border-slate-300	p-1 px-3 rounded-lg  mt-2 justify-between">
                  <p className="text-xs font-medium">Status</p>
                  {rowData?.deleted === false ? (
                    <p className="font-medium flex items-center d-block text-xs">
                      <span className="w-2 h-2 rounded-full bg-green-500 mr-1"></span>
                      Active
                    </p>
                  ) : (
                    <p className="flex items-center d-block text-xs">
                      <span className="w-2 h-2 rounded-full bg-red-500 mr-1"></span>
                      Inactive
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div className="border	border-slate-300	p-1 px-3 rounded-lg  mt-3">
                  <p className="text-xs font-medium py-2 border-b">
                    Hub Detail
                  </p>

                  <div>
                    <p className="text-xs font-medium mt-3">Location</p>
                    <p className="text-xs">
                      {rowData?.hubCity}, {rowData?.hubPinCode}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs font-medium mt-3 ">Hub E-Mail</p>
                    <p className="text-xs mb-2">{rowData?.shopEmail}</p>
                  </div>
                  <div>
                    <p className="text-xs font-medium mt-3">Fleets</p>
                    <p className="text-xs">{rowData?.currentFleets}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 ml-4 w-2/3">
              <FormControl fullWidth>
                <label htmlFor="email" className="mb-3">
                  Additional Comments:
                </label>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={6}
                  onChange={(e) => {
                    setStatusRemark(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex justify-center mt-2">
            {rowData?.deleted === true ? (
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                onClick={handleSubmit}
              >
                Activate Hub
              </Button>
            ) : (
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                onClick={handleSubmit}
              >
                Deactivate Hub
              </Button>
            )}
          </div>
          <Snackbar
            open={showSuccessSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleSnackbarClose}
              severity="success"
            >
              STATUS UPDATED!
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleSnackbarClose}
              severity="error"
            >
              {SnackbarMessage.replace(/_/g, " ")}
            </Alert>
          </Snackbar>
        </Box>
      </Modal>
    </div>
  );
}
