import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Toolbar, Typography, TextField, InputAdornment,
  Snackbar, Alert, LinearProgress, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  Stack,
  Link
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import RiderDetailPopup from "../../UI/AppModal/RiderDetailAndTracking/RiderDetailPopup";
import CircularProgress from "@mui/material/CircularProgress";
import { RiderKycApi } from "../../../API/RiderKycApi";
import {  RiderKycList } from "../../../@Types/ComponentsInterface/RiderDetailsKycProps";
import "./RiderDetailsKyc.css";
import VisibilityIcon from '@mui/icons-material/Visibility';

const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
};

export default function RiderDetailTracking() {
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [riderDetailTrackingData, setRiderDetailTrackingData] = useState<RiderKycList[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5;
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/Dashboard");
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    setLoading(true);
    RiderKycApi.api?.riderDetailTrackingList(searchVal, page +1, rowsPerPage)
      .then((response) => {
        setLoading(false);
        if (response.data.activeMerchantRequest.length > 0) {
          setRiderDetailTrackingData(response.data.activeMerchantRequest);
          setTotalCount(response.data.count);
        } else {
          setRiderDetailTrackingData([]);
          setTotalCount(1);
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, rowsPerPage, searchVal]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(Math.max(0, Math.min(newPage, pageCount - 1)));
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={`pagination-number ${
            page === pageNumber ? "active" : "unactive"
          }`}
          style={{ marginLeft: "6px", cursor: "pointer" }}
        >
          {pageNumber + 1}
        </span>
      );
    });
  };
  
  const handleAdd = (uid: {
    id: any;
    tlEnable: any;
    requestId:any;
    merchantId:any;
    row: { original: { id: any; tlEnable: any; requestId:any; merchantId:any; } };
  }) => {
    //   setUserId(uid);
    navigate("/riderdeatilkyc", {
      state: { id: uid.id, tlEnable: uid.tlEnable, userid:uid.requestId, merchantId:uid.merchantId },
    });
  };
  function capitalizeFirstLetter(str: string | undefined): string {
    if (!str) return "";
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div style={{ height: "100%", width: "100%" }} className="border-10 bg-gradient-to-r from-slate-100 to-slate-50">
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} className="bg-gradient-to-r from-slate-100 to-slate-50">
        <Typography sx={{ flex: "1 1 100%", textAlign: "left" }} variant="h6" id="tableTitle" component="div">
          <a onClick={navigateToHome} className="cursor-pointer text-black">
            <ArrowBackIcon className="mr-4 mb-1 ml-7" />
          </a>
          <b>Rider Details & Tracking</b>
        </Typography>
        <Typography sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }} component="div">
          <HomeIcon className="mb-1" /> {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "} Rider Tracking
        </Typography>
      </Toolbar>

      <div className="border-0 rounded-3xl pt-8 m-4 bg-white ml-12 mr-12">
        <div className="flex justify-between mb-4 pl-12 pr-8">
          <div className="flex">
            <TextField
              placeholder="Search Rider Name or Client Name"
              variant="outlined"
              onChange={(search) => setSearchVal(search.target.value)}
              sx={{width:330}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {loading ? (
          <LinearProgress
            className="pl-4 pr-4"
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        ) : (
          <>
            <TableContainer className="pl-5 pr-5" style={{ width: "100%", overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderCellStyle}>Rider Name</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Request Client</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Client Type</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Mobile</TableCell>
                    <TableCell style={tableHeaderCellStyle}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {riderDetailTrackingData.map((row:any) => (
                    <TableRow key={row.id}>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>{row.riderName}</p></TableCell>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>{capitalizeFirstLetter(row.requestClient)}</p></TableCell>
                      <TableCell style={tableBodyCellStyle}>
                      <Typography
                              variant="subtitle1"
                              sx={{ color: "#00CD5B" }}
                            >
                      {row.tlEnable == 1 ? (
              <>
                <p style={{ margin: 0, color: "#000" }}>
                 Primary
                </p>
              </>
            ) : (
              <>
               <p style={{ margin: 0, color: "#000" }}>
                 Secondary
                </p>
              </>
            )}
            </Typography>
                      </TableCell>
                      <TableCell style={tableBodyCellStyle}><p style={{ margin: 0, color: "#000" }}>{row.phone}</p></TableCell>
                      <TableCell>
                      <Stack direction="row" spacing={0}>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAdd(row);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#00CD5B", textAlign:"center" }}
                            >
                              <VisibilityIcon />
                            </Typography>
                          </Link>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pl-6 pt-4 pb-2 pr-16">
              <div className="pagination" style={{ display: "flex", justifyContent: "space-between" }}>
                <select
                  className="pl-2 pr-2 rounded"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                  style={{ backgroundColor: "#EBEBEB", fontFamily: "Rubik", fontSize: "16px" }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <div>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 0}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginRight: "6px",
                    }}
                  >
                    {"<"}
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= pageCount - 1}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginLeft: "6px",
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
            </div>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
              <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">No data found</Alert>
            </Snackbar>
          </>
        )}
      </div>
    </div>
  );
}
