import React, { useEffect, useState } from "react";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import DashboardFrontCardHyperTrack from "../../../components/Layouts/DashboardFrontCardThreeWheeler/DashboardFrontCard";
import {
  Alert,
  FormControl,
  LinearProgress,
  Link,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Grid,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { CSVLink } from "react-csv";


const HyperTrackHomepage = () => {

  const eautocsvHeaders = [
    { label: "Date", key: "Date" },
    { label: "Client ID", key: "Client_ID" },
    { label: "City", key: "city" },
    { label: "Rider Name", key: "Rider_Name" },
    { label: "Client", key: "Client" },
    { label: "User ID", key: "userId" },
    { label: "Cycle ID", key: "cycleId" },
    { label: "QrCode", key: "QrCode" },
    { label: "Ride ID", key: "rideId" },
    { label: "Fleet Name", key: "fleet_name" },
    { label: "IOT Type", key: "IOT_Type" },
    { label: "Start Time", key: "Start_Time" },
    { label: "IOT Distance", key: "iot_distance" },
    { label: "Active Duration", key: "Active_Duration" },
    { label: "Stop Duration", key: "Stop_Duration" },
    { label: "Drive Duration", key: "Drive_Duration" },
    { label: "Average Speed", key: "Average_Speed" },
    { label: "Disconnection Time", key: "Disconnection_time" }, 


  ];
  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
  };
  async function getLocality(
    apiKey: string,
    latitude: number,
    longitude: number
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent) {
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }

  const calculateRelativeDate = (days: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [hotspotsLoading, setHotspotsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [hotspotListData, setHotspotListData] = useState<any>();  
  const [eautohotspotListData, setEAutoHotspotListData] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(
    calculateRelativeDate(9)
  );
  const [endDate, setEndDate] = useState<Date | null>(calculateRelativeDate(2));
  const [totalCount, setTotalCount] = useState<any>();
  const [summary, setSummary] = useState<any>();
  const [merchantList, setMerchantList] = useState<any>();
  const [cityList, setCityList] = useState<any>();
  const [city, setCity] = useState<any>();
  const [merchant, setMerchant] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [hotspots, setHotspots] = useState<any>();
  const [addressData, setAddressData] = useState<
    Array<{ location: string | null; totalOrders: number }>
  >([]);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [searchValInput, setSearchValInput] = useState<string | null>(null);

  const fetchHotspotAddresses = async () => {
    const newAddressData: Array<{
      location: string | null;
      totalOrders: number;
    }> = [];

    for (const hotspot of hotspots) {
      try {
        const addresses = await getLocality(
          googleMapApiKey,
          hotspot.Hotspot_Lat,
          hotspot.Hotspot_Long
        );
        const location = addresses !== null ? addresses : "Unknown Address";
        newAddressData.push({ location, totalOrders: hotspot.totalOrders });
      } catch (error) {
        console.error(error);
        newAddressData.push({
          location: "Unknown Address",
          totalOrders: hotspot.totalOrders,
        });
      }
    }

    setAddressData(newAddressData);
  };

  useEffect(() => {
    if (hotspots && hotspots.length > 0) {
      fetchHotspotAddresses();
    }
  }, [hotspots]);
  const pageRange = 5;
  const navigate = useNavigate();

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // Handle date range change

    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };
  const handleSearchClick = () => {
    setSearchVal(searchValInput);
  };
  const handleClearSearch = () => {
    setSearchVal("");
    setSearchValInput("");
  };
  useEffect(() => {
    const apiUrl = `${searchVal ? `search=${searchVal}` : ""}`;
    setLoading(true);
    setSummaryLoading(true);
    setHotspotsLoading(true);
    HyperTrackHome.api
      .threewheelerTabledata(startDate, endDate, page + 1, merchant, city, apiUrl)
      .then((e) => {
        console.log('3w-data', e)
        if (Array.isArray(e.loader_details?.records) && e.loader_details?.records.length > 0) {
          setHotspotListData(e.loader_details?.records);
          setLoading(false);
          if (e && typeof e.loader_details?.length !== "undefined") {
            setTotalCount(e.loader_details?.length);
          }
        } else {
          setShowSnackbar(true);
        }
      });
     
      HyperTrackHome.api.eautohotspotListResponse().then((e) => {
        setEAutoHotspotListData(e.data);
      });
    HyperTrackHome.api.merchantListHyperTrack().then((e) => {
      setMerchantList(e.merchant_list);
    });
    HyperTrackHome.api.cityListHyperTrack().then((e) => {
      setCityList(e.city_list);
    });
    HyperTrackHome.api
      .hotspotArea(startDate, endDate, merchant, city)
      .then((e) => {
        setHotspots(e);
        setHotspotsLoading(false);
      });
  }, [endDate, page, merchant, city, searchVal]);
  useEffect(() => {
    HyperTrackHome.api
      .threewheelersummaryData(startDate, endDate, merchant, city)
      .then((e) => {
        setSummary(e.loader_data);
        setSummaryLoading(false);
      });
  }, [endDate, merchant, city]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  const handleAdd = (uid: {
    cycleId: any;
    userId: any;
    Date: any;
    row: { original: { userId: any; Date: any; cycleId: any } };
  }) => {
    //   setUserId(uid);
    navigate("/zypptracker/ridertrackthreewheeler", {
      state: { userid: uid.userId, date: uid.Date, cycleid: uid.cycleId },
    });
  };
  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  function roundToDecimalPlaces(number: number) {
    const multiplier = 10 ** 2;
    return Math.round(number * multiplier) / multiplier;
  }
  return (
    <div className="flex flex-wrap animate__animated animate__fadeInLeft bg-[#f2f2f2] px-12">
      <div className="flex justify-between w-full mt-4">
        <div className="flex">
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
             3W ZyppTracker
          </Typography>
        </div>
       
          {/* <div className="flex items-center w-1/4 mr-6">
            <p className="mr-2 font-semibold">City: </p>
            <FormControl fullWidth>
              <select
                id="city"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                style={{border:1, borderColor:'#c0baba', borderStyle:'solid', padding:7, borderRadius:3}}
              >
                <option value="" selected>
                  All
                </option>
                {cityList?.map((city: string, index: number) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </FormControl>
          </div>
          <div className="flex items-center w-1/3 mr-6">
            <p className="mr-2 font-semibold">Merchant: </p>
            <FormControl fullWidth>
            <select
                id="merchant"
                onChange={(e) => {
                  setMerchant(e.target.value);
                }}
                style={{border:1, borderColor:'#c0baba', borderStyle:'solid', padding:7, borderRadius:3}}
              >
                <option value="" selected>
                  All
                </option>
                {merchantList?.map((merchant: string, index: number) => (
                  <option key={index} value={merchant}>
                    {merchant}
                  </option>
                ))}
              </select>
            </FormControl>
          </div> */}
          <div className="flex items-center ">
            <p className="mr-2 font-semibold">Date Range: </p>
            <RangeDatePicker
              onChange={handleDateChange}
              maxDaysAdd={-2}
              minDaysSub={92}
              defaultStartDate={9}
              defaultEndDate={-2}
            />
          </div>
        
      </div>
      
      {summaryLoading ? (
        <Grid className="flex w-full py-4">
         <LinearProgress
                sx={{
                  width:'100%',
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                }}
              />
        </Grid>
      ) : summary ? (
        <div className="flex w-full text-left my-8">
          <DashboardFrontCardHyperTrack
              fromColorClass="from-[#000]"
              toColorClass="to-[#92E3A9]"
              textFirstLine="Total User Count"
              totalcount={roundToDecimalPlaces(summary?.total_loader_count)}
              shorttext={""}
              imageURL="/assets/hyper-track-icon/distance.svg"
               avgcount={0} avgtext={""}          />

          <DashboardFrontCardHyperTrack
              fromColorClass="from-[#0CAF55]"
              toColorClass="to-[#92E3A9]"
              textFirstLine="Total Ride Distance"
              totalcount={roundToDecimalPlaces(summary?.total_ride_distance)}
              shorttext="km"
              imageURL="/assets/hyper-track-icon/watch.svg" avgcount={0} avgtext={""}              
              />

          <DashboardFrontCardHyperTrack
              fromColorClass="from-[#0CAF55]"
              toColorClass="to-[#92E3A9]"
              textFirstLine="Total Drive Duration"
              totalcount={roundToDecimalPlaces(summary?.total_drive_duration_loader)}
              shorttext="hr"
              imageURL="/assets/hyper-track-icon/user-time.svg" avgcount={0} avgtext={""}          />
         
        </div>
      ) : (
        <div className="flex w-full text-left my-8 px-16">
          <p>Error loading summary data</p>
        </div>
      )}

      <>
        <div className="w-full">
        <div className="flex w-full justify-end mt-5 mb-5">
         
          <div className="flex mr-4">
          <CSVLink
                data={eautohotspotListData || []}
                headers={eautocsvHeaders}
                filename={`3w_rider_data.csv`}
                style={{marginRight:'10px'}}
              >
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                 3w Data Download CSV
                </Button>
              </CSVLink>
          
            </div>
            </div>
          {loading ? (
            <LinearProgress
              className="pl-6 pr-6"
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
                width: "100%",
              }}
            />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                      S.No
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      User Id
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                      Rider Name
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                      Qr Code
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Fleet Name
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Distance
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.25 }}>
                      Active Duration
                    </TableCell>
                   
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hotspotListData?.map((row: any) => (
                    <TableRow sx={{ display: "flex" }} key={row.index}>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                        {row.index}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.userId}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                        {row.Rider_Name}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                        {row.QrCode}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.fleet_name}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ flex: 1, alignItems: "center" }}
                      >
                        {row.iot_distance}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1.25 }}>
                        {row.Active_Duration}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        <Stack direction="row" spacing={0}>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAdd(row);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#00CD5B" }}
                            >
                              <MapIcon />
                            </Typography>
                          </Link>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div className="pl-2 pt-4 pb-2 pr-16">
            <div
              className="pagination"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 0}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginRight: "6px",
                  }}
                >
                  {"<"}
                </button>
                {renderPageNumbers()}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= pageCount - 1}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginLeft: "6px",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Snackbar open={showSnackbar} autoHideDuration={6000}>
          <Alert sx={{ width: "100%" }} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};

export default HyperTrackHomepage;
