import axios, { AxiosInstance } from "axios";
import { RiderDetailTrackingResponse } from "../@Types/DRO/ApiResponses";
export class RiderDetailTrackingApi {
  public static api = new RiderDetailTrackingApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://beta.bcykal.com/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  riderDetailTrackingList(
    page?: number | null,
    pageSize?: number | null,
    searchParam?: string
  ): Promise<RiderDetailTrackingResponse> {
    let apiUrl = "mobycy/get/riderTrackingDetails/v2";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance
      .get<RiderDetailTrackingResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          ...(page !== undefined && { page }),
          ...(pageSize !== undefined && { pageSize }),
        },
      })
      .then((e) => {
        return e.data;
      });
  }
}
