import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  //Polygon
} from "@react-google-maps/api";
import moment from "moment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import "./style.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Path } from "./googleMapLive";
import { Grid, Link, Typography } from "@mui/material";
//import axios from 'axios';

function Map({
  paths,
  homeLat,
  homeLng,
  workLat,
  workLng,
  currentLat,
  currentLng,
}: {
  paths: Path[];
  workLat: number;
  workLng: number;
  homeLat: number;
  homeLng: number; // Assuming workhomelocation is an object containing work_home_data property
  currentLat:number;
  currentLng:number;

  
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [positionIndex, setPositionIndex] = useState(0);
  const [workAddress, setWorkAddress] = useState<string | null>(null);
  const [homeAddress, setHomeAddress] = useState<string | null>(null);
  const [currentAddress, setCurrentAddress] = useState<string | null>(null);

  const [startAddress, setStartAddress] = useState<string | null>(null);
  const [endAddress, setEndAddress] = useState<string | null>(null);
  const [selectedMarkerstart, setSelectedMarkerstart] = useState<number | null>(null);
  const [selectedMarkerend, setSelectedMarkerend] = useState<number | null>(null);
  const [selectedMarkerwork, setSelectedMarkerwork] = useState<number | null>(null);
  const [selectedMarkerhome, setSelectedMarkerhome] = useState<number | null>(null);
  const [selectedMarkercurrent, setSelectedMarkerCurrent] = useState<number | null>(null);

  const [loadingWorkAddress, setLoadingWorkAddress] = useState(true);
  const [loadingHomeAddress, setLoadingHomeAddress] = useState(true);
  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  let mapPaths: Path[] = paths;

 console.log('mapPaths', mapPaths)

  let interval: NodeJS.Timer | undefined;
  const icon1 = {
    url: "https://zypp.app/mailer/Map-icon.png",
    scaledSize: new window.google.maps.Size(35, 35),
    anchor: new window.google.maps.Point(20, 20),
    scale: 0,
  };

  const mid = Math.floor(mapPaths.length / 2);
  const centerPathLat = mapPaths[mid]?.latitude;
  const centerpathLng = mapPaths[mid].longitude;
  const startLat = mapPaths[0].latitude;
  const startLng = mapPaths[0].longitude;
  const end = mapPaths.length - 1;
  const endPathLat = mapPaths[end].latitude;
  const endpathLng = mapPaths[end].longitude;

  const starttime = mapPaths[0].time_stamp;
  const endtime = mapPaths[end].time_stamp;

  let StartDate = moment(starttime).format("DD-MM-YYYY");
  let EndDate = moment(endtime).format("DD-MM-YYYY");

  let StartTime = moment(starttime).format("HH:mm:ss");
  let EndTime = moment(endtime).format("HH:mm:ss");


  console.log('time s e',StartDate, EndDate);
  console.log('StartTime',StartTime, EndTime)

  

  const convertPathToLatLngLiteral = (
    path: Path[]
  ): google.maps.LatLngLiteral[] => {
    return path.map((point) => ({
      lat: point.latitude,
      lng: point.longitude,
      distance: point.Distance,
    }));
  };

  const [mapPath, setMapPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    const pathAsLatLngLiteral = convertPathToLatLngLiteral(paths);
    setMapPath(pathAsLatLngLiteral);
  }, [paths]);
  async function getLocality(
    apiKey: string,
    latitude: number | undefined,
    longitude: number | undefined
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
console.log('apimap urla', apiUrl)
    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponentone = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_2")
        );
           const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent && localityComponentone) {
          const localityone = localityComponentone.long_name;
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${localityone},${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }
  const handleMarkerStart = (e: any) => {
    setSelectedMarkerstart(e);
  };
  const handleMarkerEnd = (e: any) => {
    setSelectedMarkerend(e);
  };
  const handleMarkerWork = (e: any) => {
    setSelectedMarkerwork(e);
  };
  const handleMarkerHome = (e: any) => {
    setSelectedMarkerhome(e);
  };
  const handleMarkerCurrent = (e: any) => {
    setSelectedMarkerCurrent(e);
  };

  const fetchData = async () => {
    try {
      const fetchedallStartAddress = await getLocality(
        googleMapApiKey,
        startLat,
        startLng
      );
      const fetchedallEndAddress = await getLocality(
        googleMapApiKey,
        endPathLat,
        endpathLng
      );
      const fetchedallHomeAddress = await getLocality(
        googleMapApiKey,
        homeLat,
        homeLng
      );
      const fetchedallWorkAddress = await getLocality(
        googleMapApiKey,
        workLat,
        workLng
      );
      const fetchedCurrentAddress = await getLocality(
        googleMapApiKey,
        currentLat,
        currentLng
      );
      setStartAddress(fetchedallStartAddress);

      setEndAddress(fetchedallEndAddress);
      setWorkAddress(fetchedallWorkAddress);
      setHomeAddress(fetchedallHomeAddress);
      setCurrentAddress(fetchedCurrentAddress);

      setLoadingHomeAddress(false);
      setLoadingWorkAddress(false);


     
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingWorkAddress(false);
      setLoadingHomeAddress(false);
    }
  };
  useEffect(() => {
    fetchData();
    console.log('mapPath', mapPath)
  }, []);
  const homeMarker = {
    title: `Home: ${homeAddress || "Unknown Address"}\nLatitude: ${homeLat}\nLongitude: ${homeLng}\nGoogle Maps Link: https://maps.google.com/?q=${homeLat},${homeLng}`,
    icon: "/assets/hyper-track-icon/home-location.png", // Replace with the path to your home marker icon
    position: { lat: homeLat, lng: homeLng },
};

  const workMarker = {
    title: `Work: ${workAddress || "Unknown Address"}`,
    icon: "/assets/hyper-track-icon/work-location.png", // Replace with the path to your work marker icon
    position: { lat: workLat, lng: workLng },
  };
  const currentMarker = {
    title: `Work: ${currentAddress || "Unknown Address"}`,
    icon: "/assets/hyper-track-icon/reddot.svg", // Replace with the path to your work marker icon
    position: { lat: currentLat, lng: currentLng },
  };
  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setPositionIndex((prevIndex) => {
          if (prevIndex === mapPath.length - 1) {
            setIsPlaying(false); // Stop playing if reached the end
            clearInterval(intervalId);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 1000); // Adjust the interval time as needed
    }

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [isPlaying, mapPath.length]);

  let iconMarkerStart = "https://zypp.app/clientdashboard-mailer/startpath.png";
  let iconMarkerEnd = "https://zypp.app/clientdashboard-mailer/endpath.png";

  

  const polylineOptionsBlue = {
    strokeColor: "#0826e5",
    strokeWeight: 2,
    strokeOpacity: 0.6,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 99999,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        },
        repeat: "40px",
        offset: "100%",
      },
    ],
  };
  const polylineOptions = {
    strokeColor: "#ffa500",
    strokeOpacity: 1,
    strokeWeight: 3,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 3,
          fillColor: "#ffa500",
          fillOpacity: 1,
          strokeColor: "#ffa500",
        },
        offset: "100%",
      },
    ],
  };
  
  
  return (
    <div>
      {/* <div style={{textAlign:'right', display:'flex', justifyContent:'flex-end'}}>
      <Button
            onClick={tooglePlay}
            style={{ backgroundColor: "#00CD5B", marginBottom: 10, color: "#fff" }}
          >
            {isPlaying ? "Pause" : "Play"}
          </Button>
          </div> */}
      <Card variant="outlined" >
      <Grid className="flex items-center mb-2 justify-end mr-3 py-3">
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <div>
              <img width={30} height={30}
                  src="/assets/hyper-track-icon/reddot.svg"
                />
              </div>
              <Typography sx={{ ml: 1 }}>Live Location</Typography>
            </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center",ml:4 }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#3c96ff",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Home Location</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center", ml:4 }}>
              <Typography
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#ef4c45",
                  borderRadius: 50,
                }}
              >
                &nbsp;
              </Typography>
              <Typography sx={{ ml: 1 }}>Work Location</Typography>
            </Grid>
          </Grid>
        <div className="gMapCont">
          <GoogleMap
            onLoad={(mapDetails: google.maps.Map) => {
              setMap(mapDetails); // Map object stored
            }}
            zoom={11}
            center={{ lat: centerPathLat, lng: centerpathLng }}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
            }}
            // journeydetails={journeydetails}
            mapContainerStyle={{
              width: "100%",
              height: "44rem",
            }}
          >
            {!loadingHomeAddress && !loadingWorkAddress && (
              <>
                {/* Add markers for home and work */}
                <Marker
                icon={homeMarker.icon}
                position={homeMarker.position}
                onMouseOver={() => handleMarkerHome(homeMarker)} // No semicolon needed here
            />

                  {selectedMarkerhome !== null && (
            <>
              <InfoWindow
                position={homeMarker.position}
                options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                onCloseClick={() => setSelectedMarkerhome(null)}
              >
                <div>
                  <p><b>Home: </b>{homeAddress || "Unknown Address"}</p>
                  <p><b>Latitude:</b> {homeLat} <b>Longitude:</b> {homeLng}</p>
                  <p>
                  <b>Google Maps Link:</b>  <a target="_blank" href={`https://maps.google.com/?q=${homeLat},${homeLng}`}  style={{ cursor: "pointer", color:'blue' }}
                    >
                        https://maps.google.com/?q={homeLat},{homeLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
              
            </>
          )}
                 
                <Marker
                  title={workMarker.title}
                  icon={workMarker.icon}
                  position={workMarker.position}
                  onMouseOver={() => handleMarkerWork(workMarker)} // No semicolon needed here

                >
                 {selectedMarkerwork !== null && (
            <>
              <InfoWindow
                position={workMarker.position}
                options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                onCloseClick={() => setSelectedMarkerwork(null)}
              >
                <div>
                  <p><b>Work: </b>{workAddress || "Unknown Address"}</p>
                  <p><b>Latitude:</b> {workLat} <b>Longitude:</b> {workLng}</p>
                  <p>
                  <b>Google Maps Link:</b>  <a target="_blank" href={`https://maps.google.com/?q=${workLat},${workLng}`}  style={{ cursor: "pointer", color:'blue' }}
                    >
                        https://maps.google.com/?q={workLat},{workLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
              
            </>
          )}
          </Marker>
          <Marker
                  title={currentMarker.title}
                  icon={currentMarker.icon}
                  position={currentMarker.position}
                  onMouseOver={() => handleMarkerCurrent(currentMarker)} // No semicolon needed here

                >
                 {selectedMarkercurrent !== null && (
            <>
              <InfoWindow
                position={currentMarker.position}
                options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                onCloseClick={() => setSelectedMarkerCurrent(null)}
              >
                <div>
                  <p><b>Live Location: </b>{currentAddress || "Unknown Address"}</p>
                  <p><b>Latitude:</b> {currentLat} <b>Longitude:</b> {currentLng}</p>
                  <p>
                  <b>Google Maps Link:</b>  <a target="_blank" href={`https://maps.google.com/?q=${currentLat},${currentLng}`}  style={{ cursor: "pointer", color:'blue' }}
                    >
                        https://maps.google.com/?q={currentLat},{currentLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
              
            </>
          )}
          </Marker>
              </>
            )}
          
            <Marker
              // label={new window.google.maps.Label("")}
              icon={iconMarkerStart}
              position={{ lat: startLat, lng: startLng }}
              onClick={() => handleMarkerStart(mapPaths)}

            />
             {selectedMarkerstart !== null && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -100) }}
                onCloseClick={() => setSelectedMarkerstart(null)}
                position={{
                  lat: startLat,
                  lng: startLng,
                }}
              >
                <div>
                  <p>
                    <b>{StartDate} {StartTime}</b>
                  </p>
                  <p>
                    <b>Work: </b>
                    {startAddress || "Unknown Address"}
                  </p>
                  <p>
                    <b>Latitude:</b> {startLat} <b>Longitude:</b> {startLng}
                  </p>
                  <p>
                    <b>Google Maps Link:</b>{" "}
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${startLat},${startLng}`}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      https://maps.google.com/?q={startLat},{startLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
            <Marker
              // name={"SOMA"}
              icon={iconMarkerEnd}
              position={{ lat: endPathLat, lng: endpathLng }}
              onClick={() => handleMarkerEnd(mapPaths)}

            />
            {selectedMarkerend !== null && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -100) }}
                onCloseClick={() => setSelectedMarkerend(null)}
                position={{
                  lat: endPathLat,
                  lng: endpathLng,
                }}
              >
                <div>
                  <p>
                    <b>{EndDate} {EndTime}</b>
                  </p>
                  <p>
                    <b>Work: </b>
                    {endAddress || "Unknown Address"}
                  </p>
                  <p>
                    <b>Latitude:</b> {endPathLat} <b>Longitude:</b> {endpathLng}
                  </p>
                  <p>
                    <b>Google Maps Link:</b>{" "}
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${endPathLat},${endpathLng}`}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      https://maps.google.com/?q={endPathLat},{endpathLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
           
           

            <Polyline path={mapPath} options={polylineOptionsBlue} />

            {mapPath && (
              <>
                <Polyline
                  path={mapPath.slice(0, positionIndex + 1)}
                  options={polylineOptions}
                />

                <Marker icon={icon1} position={{ lat: 0, lng: 0 }} />
              </>
            )}
           

            
          </GoogleMap>
        </div>
      </Card>
    </div>
  );
}

// const WrappedMap = withScriptjs(withGoogleMap(Map));

export default Map;
