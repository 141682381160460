import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { ClientApi } from "../../API/ClientApi";
import DashboardFrontCard from "../../components/Layouts/DashboardFrontCard/DashboardFrontCard";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import CountUp from "react-countup";
import "react-datepicker/dist/react-datepicker.css";
import "animate.css";
import {
  Pie,
  PieChart,
  Cell,
  ResponsiveContainer,
  Line,
  LineChart,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Label,
} from "recharts";

import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import { AppHeatMap } from "../../components/Layouts/GoogleMaps/HeatMap";
import GoogleMapApi from "../../components/Layouts/GoogleMaps/Maps";
import { ZyppRidersResponse } from "../../@Types/ComponentsInterface/ZyppRiderResponse";
import { CityWiseResponse } from "../../@Types/ComponentsInterface/CityWiseResponse";
import { HotRouteResponse } from "../../@Types/ComponentsInterface/HotRouteResponse";
import LinearProgress from "@mui/material/LinearProgress";
import "react-datepicker/dist/react-datepicker.css";
import RangeDatePicker from "../../components/UI/AppDatePicker/AppDatePicker";
import Carousel from "../../components/Layouts/NewsFeed/NewsCarousel";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "G-NWTLSSYVR2",
};
TagManager.initialize(tagManagerArgs);
console.log(tagManagerArgs);
const chartDataEv = [
  { name: "Group A", value: 50 },
  { name: "Group B", value: 50 },
];
const chartDataDelivery = [
  { name: "Group A", value: 50 },
  { name: "Group B", value: 50 },
];
const BARCOLORS = ["#a78bfa", "#ddd6fe"];

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "#00CD5B",
  width: theme.spacing(4),
  height: theme.spacing(4),
  fontSize: "12px",
  fontWeight: "bold",
  color: "#FFFFFF",
  alignItems: "center",
  marginBottom: "6px",
}));

const LINECOLORS = ["#00CD5B", "#a78bfa"];
const Homepage = () => {
  const COLORS = ["#EC609C", "#00CD5B"];

  const [carbonData1, setCarbonData1] = useState<any>();
  const [treedata, setTreedata] = useState<any>([]);
  const [newsdata, setNewsdata] = useState<any>([]);
  const [deliveriesData, setDeliveriesData] = useState<any>([]);
  const [totalRiderEvAndDelivery, setTotalRiderEvAndDelivery] = useState<any>();
  const [barData, setBarData] = useState<any[]>([]);
  const [riderData, setRiderData] = useState<any>();
  const [cityOrderDensity, setCityOrderDensity] = useState<any>();
  const [hotRoutes, setHotRoutes] = useState<any>();
  const [fleetGrowthDeliveryData, setFleetGrowthDeliveryData] = useState<any>();
  const [fleetGrowthData, setFleetGrowthData] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    ClientApi.api.newsCardInfo().then((e) => {
      setNewsdata(e);
      setLoading(false);
    });
    ClientApi.api.topZyppRiders().then((e) => {
      setRiderData(e.riderData);
    });

    ClientApi.api.barChartApi().then((e) => {
      setBarData(e);
    });
    ClientApi.api.fleetGrownJourney().then((e) => {
      setFleetGrowthData(e.fleetGrowthData);
    });
    ClientApi.api.fleetGrownJourneyDelivery().then((e) => {
      setFleetGrowthDeliveryData(e.fleetGrowthData);
    });
  }, []);
  useEffect(() => {
    ClientApi.api.riderEvAndTotalDeleveries(startDate, endDate).then((e) => {
      setTotalRiderEvAndDelivery(e);
    });

    ClientApi.api.carbonSavedData(startDate, endDate).then((e) => {
      setCarbonData1(e);
    });

    ClientApi.api.treeSavedData(startDate, endDate).then((e) => {
      setTreedata(e);
    });

    ClientApi.api.deliveriesDoneData(startDate, endDate).then((e) => {
      setDeliveriesData(e);
    });

    ClientApi.api.hotRoutes(startDate, endDate).then((e) => {
      setHotRoutes(e.hotRoutes);
    });

    ClientApi.api.cityWiseSplit(startDate, endDate).then((e) => {
      setCityOrderDensity(e.cityOrderDensity);
    });
  }, [endDate]);

  chartDataEv[0].value = totalRiderEvAndDelivery?.liveRiders;
  chartDataEv[1].value = totalRiderEvAndDelivery?.allottedRiders;
  chartDataDelivery[0].value = totalRiderEvAndDelivery?.totalDistance;
  chartDataDelivery[1].value = totalRiderEvAndDelivery?.totalDeliveries;
  const barChartData = barData;
  const treeDataReverse = treedata?.monthData
    ? [...treedata.monthData].reverse()
    : [];

  const CustomXAxisTick = (props: { x: any; y: any; payload: any }) => {
    const { x, y, payload } = props;
    const monthLabel = payload.value.split(" ")[0]; // Split the label and take the first part
    return (
      <text x={x} y={y} dy={16} textAnchor="middle" fontSize={10} fill="#666">
        {monthLabel}
      </text>
    );
  };
  const modifiedFleetGrowthData = fleetGrowthData?.map(
    (item: { deliveryData: number }) => ({
      ...item,
      deliveryData: item.deliveryData, // Divide deliveryData by 100
    })
  );
  const filteredFleetGrowthData = modifiedFleetGrowthData?.filter(
    (item: { deliveryData: number }) => item.deliveryData !== 0
  );
  const modifiedFleetGrowthDeliveryData = fleetGrowthDeliveryData?.map(
    (item: { deliveryData: number }) => ({
      ...item,
      deliveryData: item.deliveryData, // Divide deliveryData by 100
    })
  );
  const filteredFleetGrowthDeliveryData =
    modifiedFleetGrowthDeliveryData?.filter(
      (item: { deliveryData: number }) => item.deliveryData !== 0
    );
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // Handle date range change

    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  function capitalizeFirstLetter(str: string): string {
    const words: string[] = str.split(" ");
    const capitalizedWords: string[] = words.map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalizedWords.join(" ");
  }

  return (
    <>
      <div className="bg-neutral-50/[0.8] flex justify-between py-2 items-center">
        <div className="font-semibold ml-8 font-rubik">Dashboard</div>
        <div>
          <div className="flex items-center mr-8">
            <p className="mr-2 font-semibold">Date Range: </p>
            <RangeDatePicker
              onChange={handleDateChange}
              maxDaysAdd={-2}
              minDaysSub={92}
              defaultStartDate={32}
              defaultEndDate={-2}
            />
          </div>
        </div>
      </div>
      <div className="bg-slate-100 font-rubik text-sm">
        <div className="flex flex-wrap animate__animated animate__fadeInLeft">
          <DashboardFrontCard
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Carbon Saved (kgs)"
            incrementPercentage={carbonData1?.progressPercent}
            monthLineData={carbonData1?.monthData}
            imageURL="/assets/icons/happy-earth.svg"
            totalData={0}
            clientData={0}
          >
            <div>
              {/* <span>Total Carbon Saved (kgs): </span> */}
              <CountUp
                start={1000}
                end={carbonData1?.clientLastMonthValue || 0}
                duration={10}
                separator=","
              />
            </div>
            <div>
              {/* <span>Last Month's Carbon Saved (kgs): </span> */}
              <CountUp
                start={1000}
                end={carbonData1?.clientTotalValue || 0}
                duration={10}
                separator=","
              />
            </div>
          </DashboardFrontCard>

          <DashboardFrontCard
            fromColorClass="from-[#4393F4]"
            toColorClass="to-[#4393F4B8]"
            textFirstLine="Tree Saved"
            totalData={0}
            clientData={0}
            incrementPercentage={treedata?.progressPercent}
            monthLineData={treeDataReverse}
            imageURL="/assets/icons/cherry-tree.svg"
          >
            <div>
              <CountUp
                start={1000}
                end={treedata?.clientLastMonthValue || 0}
                duration={10}
                separator=","
              />
            </div>
            <div>
              <CountUp
                start={1000}
                end={treedata?.clientTotalValue || 0}
                duration={10}
                separator=","
              />
            </div>
          </DashboardFrontCard>
          <DashboardFrontCard
            fromColorClass="from-[#FFBB0D]"
            toColorClass="to-[#FFBB0D]"
            textFirstLine="Total Deliveries Done"
            totalData={0}
            clientData={0}
            incrementPercentage={deliveriesData?.progressPercent}
            monthLineData={deliveriesData?.monthData}
            imageURL="/assets/icons/take-away.svg"
          >
            <div>
              <CountUp
                start={1000}
                end={deliveriesData?.clientLastMonthValue || 0}
                duration={10}
                separator=","
              />
            </div>
            <div>
              <CountUp
                start={1000}
                end={deliveriesData?.clientTotalValue || 0}
                duration={10}
                separator=","
              />
            </div>
          </DashboardFrontCard>
        </div>

        {/* { Creating second div elements for card view } */}

        <div className="flex font-rubik animate__animated animate__fadeInRight">
          <div className="flex-grow w-3/12 h-80 m-4">
            {!totalRiderEvAndDelivery ||
            totalRiderEvAndDelivery.length === 0 ? (
              <div className="flex-grow h-36 rounded-lg relative bg-[#fff] shadow">
                <div className="flex justify-end items-center">
                  <div className="flex">
                    <IconButton className="mr-2" size="small" aria-label="Edit">
                      <InfoIcon />
                    </IconButton>
                    <IconButton size="small" aria-label="Edit">
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
                Loading...
              </div>
            ) : (
              <div className="flex-grow h-36 rounded-lg relative bg-[#fff] shadow">
                <div className="flex justify-end items-center">
                  <div className="flex mt-1">
                    <IconButton className="mr-2" size="small" aria-label="Edit">
                      <InfoIcon />
                    </IconButton>
                    <IconButton size="small" aria-label="Edit">
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="flex items-center justify absolute inset-0 w-full">
                  {/* Donut Chart */}
                  <div className="w-1/3 h-20 relative">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={chartDataEv}
                          cx="48%"
                          cy="50%"
                          innerRadius="80%"
                          outerRadius="90%"
                          dataKey="value"
                        >
                          {chartDataEv.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS?.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                    <img
                      src={"/assets/icons/zypp-evs.svg"}
                      alt="Center Image"
                      className="w-10 h-10 absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4"
                    />
                  </div>
                  <div className="w-2/3 text-center">
                    <p className="font-bold text-[#263238] text-left text-sm mb-1">
                      Your Zypp EV’s
                    </p>
                    <table className="w-full">
                      <tr>
                        <td className="text-[#00CD5B] text-sm font-semibold text-left">
                          Alloted riders
                        </td>
                        <td className="text-[#EC609C] text-sm font-semibold text-left">
                          Live riders
                        </td>
                      </tr>
                      <tr>
                        <td className="text-[#00CD5B] text-sm font-extrabold text-left">
                          {totalRiderEvAndDelivery?.allottedRiders.toLocaleString(
                            "en-IN"
                          )}
                        </td>
                        <td className="text-[#CE0159] text-sm font-extrabold text-left">
                          {totalRiderEvAndDelivery?.liveRiders.toLocaleString(
                            "en-IN"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {!totalRiderEvAndDelivery ||
            totalRiderEvAndDelivery.length === 0 ? (
              <div className="flex-grow h-36 mt-7 rounded-lg relative bg-[#fff] shadow">
                <div className="flex justify-end items-center">
                  <div className="flex mt-1">
                    <IconButton className="mr-2" size="small" aria-label="Edit">
                      <InfoIcon />
                    </IconButton>
                    <IconButton size="small" aria-label="Edit">
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
                Loading...
              </div>
            ) : (
              <div className="flex-grow h-36 mt-7 rounded-lg relative bg-[#fff] shadow">
                <div className="flex justify-end items-center">
                  <div className="flex mt-1">
                    <IconButton className="mr-2" size="small" aria-label="Edit">
                      <InfoIcon />
                    </IconButton>
                    <IconButton size="small" aria-label="Edit">
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="flex items-center justify absolute inset-0 w-full">
                  {/* Donut Chart */}
                  <div className="w-1/3 h-20 relative">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={chartDataDelivery}
                          cx="48%"
                          cy="50%"
                          innerRadius="80%"
                          outerRadius="90%"
                          dataKey="value"
                        >
                          {chartDataDelivery.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS?.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                    <img
                      src={"/assets/icons/take-away.svg"}
                      alt="Center Image"
                      className="w-10 h-10 absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4"
                    />
                  </div>
                  <div className="w-2/3 text-center">
                    <p className="font-bold text-[#263238] text-left text-sm mb-1">
                      Total Deliveries/Distance
                    </p>
                    <table className="w-full">
                      <tr>
                        <td className="text-[#00CD5B] text-sm font-semibold text-left">
                          Deliveries
                        </td>
                        <td className="text-[#EC609C] text-sm font-semibold text-left">
                          Distance (kms)
                        </td>
                      </tr>
                      <tr>
                        <td className="text-[#00CD5B] text-sm font-extrabold text-left">
                          {totalRiderEvAndDelivery?.totalDeliveries.toLocaleString(
                            "en-IN"
                          )}
                        </td>
                        <td className="text-[#CE0159] text-sm font-extrabold text-left">
                          {totalRiderEvAndDelivery?.totalDistance.toLocaleString(
                            "en-IN"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex-grow w-4/12 h-80 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center">
              <h2 className="text-center ml-4 mx-1 mt-2 font-bold text-base text-[#2B313F]">
                Area Heatmap
              </h2>
              <div className="flex">
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center p-3 animate_animated animate__slideInUp">
              <AppHeatMap />
            </div>
          </div>
          <div className="flex-grow w-5/12 h-80 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center">
              <h2 className="text-center ml-4 mx-1 mt-2 mb-3 font-bold text-base text-black">
                Live EV Riders
              </h2>
              <div className="flex">
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center px-4 pb-3">
              <div style={{ width: "37vw", height: "16.5rem" }}>
                <GoogleMapApi />
              </div>
              {/* <img src={"/assets/icons/map.svg"} alt="" className="w-9/12" /> */}
            </div>
          </div>
        </div>
        <div className="flex">
          {/* <div className="flex-grow w-1/3 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center">
              <p className="text-center ml-4 mx-1 mt-2 mb-2 font-bold text-base text-black">
                Top 5 Hot Routes with Order Density
              </p>
              <div className="flex">
                <IconButton className="mr-2" size="small" aria-label="Edit">
                  <InfoIcon />
                </IconButton>
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="px-5">
              {loading ? (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : hotRoutes && hotRoutes.length > 0 ? (
                <table className="w-full border-collapse">
                  <tbody>
                    {hotRoutes.map(
                      (hotroute: HotRouteResponse, index: number) => (
                        <tr key={index} className="p-1">
                          <td className="text-center ">
                            <StyledAvatar aria-label="recipe" className="mt-1">
                              {hotroute.shopName.slice(0, 2).toUpperCase()}
                            </StyledAvatar>
                          </td>
                          <td className="text-left font-bold text-sm pl-2">
                            {hotroute.shopName}
                          </td>
                          <td className="text-[#00CD5B] font-bold text-sm ">
                            {hotroute.orderDensity.toLocaleString("en-IN")}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p className="font-bold text-[#CE0159] mt-6 mr-6">
                  No Data Found
                </p>
              )}
            </div>
          </div> */}
          <div className="flex-grow w-1/6 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center">
              <h2 className="text-center mx-1 mt-2 mb-4 font-bold ml-5 text-base text-[#263238]">
                Top 5 Zypp Riders
              </h2>
              <div className="flex">
                <IconButton className="mr-2" size="small" aria-label="Edit">
                  <InfoIcon />
                </IconButton>
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="py-3 px-5">
              {loading ? (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : riderData && riderData.length > 0 ? (
                <table className="w-full border-collapse">
                  <tbody>
                    {riderData.map(
                      (riders: ZyppRidersResponse, index: number) => (
                        <tr key={index}>
                          <td className="text-center ">
                            <div className="flex justify-center h-full">
                              <img
                                src={`https://dash.bcykal.com//mobycy/api/media/file/${riders.profilePicId}`}
                                alt="Icon"
                                className="w-8 h-8 mb-2 border rounded-full border-green-500 p-0.5"
                              />
                            </div>
                          </td>
                          <td className="text-left ml-1 font-semibold text-sm pb-2 pl-1">
                            {capitalizeFirstLetter(riders.name)}
                          </td>
                          <td className="text-[#00CD5B]  font-bold text-sm pb-2">
                            ₹ {riders.earning.toLocaleString("en-IN")}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p className="font-bold text-[#CE0159] mt-6 mr-6">
                  No Data Found
                </p>
              )}
            </div>
          </div>
          <div className="flex-grow w-5/12 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center">
              <h2 className="text-center ml-4 mx-1 mt-2 mb-4 font-bold text-base text-black">
                Zypp Vintage Riders Age
              </h2>
              <div className="flex">
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="h-48 relative px-5">
              {/* Double Bar Chart */}
              {barChartData && barChartData.length > 0 ? (
                <BarChart
                  width={700}
                  height={220}
                  data={barChartData}
                  margin={{ top: 20, right: 30, bottom: 5 }}
                >
                  <CartesianGrid vertical={false} horizontal={true} />
                  <YAxis style={{ strokeOpacity: 0.4 }}>
                    <Label
                      value="Riders Age"
                      position="insideLeft"
                      dx={2} // Adjust the horizontal position
                      dy={35} // Adjust the vertical position
                      angle={-90}
                    />
                  </YAxis>
                  <XAxis
                    dataKey="range"
                    angle={-45}
                    textAnchor="end"
                    tick={
                      <CustomXAxisTick
                        x={undefined}
                        y={undefined}
                        payload={undefined}
                      />
                    }
                    style={{ strokeOpacity: 0.4 }}
                  />

                  <Tooltip />
                  <Bar
                    dataKey="value"
                    barSize={40}
                    fill={BARCOLORS[0]}
                    name="Rider Age"
                  />
                </BarChart>
              ) : (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex-grow w-auto h-80 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center">
              <h2 className="text-center ml-4 mx-1 mt-2 mb-4 font-bold text-base text-[#1F1F25]">
                Zypp Delivery Growth Journey
              </h2>
              <div className="flex">
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="h-64">
              {/* Line Chart */}
              {loading ? (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : fleetGrowthDeliveryData &&
                fleetGrowthDeliveryData.length > 0 ? (
                <ResponsiveContainer width="95%" height={250}>
                  <LineChart
                    width={600}
                    height={250}
                    data={filteredFleetGrowthDeliveryData}
                    className="ml-2"
                  >
                    <XAxis
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: 12 }}
                    />
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        if (name === "Zypp EVs") {
                          return ` ${value}`;
                        } else if (name === "Total Deliveries") {
                          return ` ${value}`; // Multiply by 100 as mentioned in your previous question
                        }
                        return value;
                      }}
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="clientData"
                      stroke={LINECOLORS[0]}
                      strokeWidth={2}
                      name="Client Deliveries"
                      dot={false}
                    />

                    <Line
                      type="monotone"
                      dataKey="zyppData"
                      stroke={LINECOLORS[1]}
                      strokeWidth={2}
                      name="Total Deliveries"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p className="font-bold text-[#CE0159] mt-6 mr-6">
                  No Data Found
                </p>
              )}
            </div>
          </div>
          <div className="flex-grow w-auto h-80 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center">
              <h2 className="text-center ml-4 mx-1 mt-2 mb-4 font-bold text-base text-[#1F1F25]">
                Zypp Fleet Growth Journey
              </h2>
              <div className="flex">
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="h-64">
              {/* Line Chart */}
              {loading ? (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : fleetGrowthData && fleetGrowthData.length > 0 ? (
                <ResponsiveContainer width="95%" height={250}>
                  <LineChart
                    width={600}
                    height={250}
                    data={filteredFleetGrowthData}
                    className="ml-2"
                  >
                    <XAxis
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: 12 }}
                    />
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        if (name === "Zypp EVs") {
                          return ` ${value}`;
                        } else if (name === "Total Deliveries") {
                          return ` ${value}`; // Multiply by 100 as mentioned in your previous question
                        }
                        return value;
                      }}
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="clientData"
                      stroke={LINECOLORS[0]}
                      strokeWidth={2}
                      name="Client Fleets"
                      dot={false}
                    />

                    <Line
                      type="monotone"
                      dataKey="zyppData"
                      stroke={LINECOLORS[1]}
                      strokeWidth={2}
                      name="Total Fleets"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p className="font-bold text-[#CE0159] mt-6 mr-6">
                  No Data Found
                </p>
              )}
            </div>
          </div>
          {/* <div className="flex-grow w-auto h-80 rounded-lg bg-[#fff] m-4 shadow">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-center ml-4 mx-1 mt-2 mb-3 font-bold text-base text-[#1F1F25]">
                City wise Split
              </h2>
              <div className="flex">
                <IconButton className="mr-2" size="small" aria-label="Edit">
                  <InfoIcon />
                </IconButton>
                <IconButton size="small" aria-label="Edit">
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className="px-5">
              {loading ? (
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : cityOrderDensity && cityOrderDensity.length > 0 ? (
                <table className="w-full border-collapse text-center">
                  <tr className="p-2">
                    <th colSpan={2} className="text-left text-sm pb-4">
                      Top Cities
                    </th>

                    <th className="text-right text-sm pb-4">Order Density</th>
                  </tr>
                  {cityOrderDensity?.map(
                    (cityWiseResponse: CityWiseResponse, index: number) => (
                      <tr key={index} className="p-2">
                        <td className="text-center w-12 text-sm">
                          <div className="flex  h-full">
                            <StyledAvatar aria-label="recipe" className="mt-1">
                              {cityWiseResponse.cityName
                                .slice(0, 2)
                                .toUpperCase()}
                            </StyledAvatar>
                          </div>
                        </td>
                        <td className="text-left font-semibold text-sm">
                          {cityWiseResponse.cityName}
                        </td>
                        <td className="text-right text-green-500 font-semibold text-sm">
                          {cityWiseResponse.orderDensity.toLocaleString(
                            "en-IN"
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </table>
              ) : (
                <p className="font-bold text-[#CE0159] mt-6 mr-6">
                  No Data Found
                </p>
              )}
            </div>
          </div> */}
        </div>
        <div className="flex-grow w-auto h-96 rounded-lg bg-[#fff] m-4 shadow px-5 font-rubik">
          <h2 className="text-left  pt-4 pb-4  font-bold text-base">
            Zypp Electric News
          </h2>
          <div className="bg-white">
            {loading ? (
              <div className="flex flex-col justify-center items-center    h-full">
                <CircularProgress sx={{ color: "black" }} />
              </div>
            ) : !newsdata.newsData || newsdata.newsData.length === 0 ? (
              <p className="font-bold text-[#CE0159] mt-6 mr-6">
                No News Availabe
              </p>
            ) : (
              <>
                <Carousel newsData={newsdata.newsData} />
              </>
            )}
          </div>
        </div>
        <h3 className="text-center">
          © 2023 Zypp Electric. All rights reserved.
        </h3>
      </div>
    </>
  );
};

export default Homepage;
