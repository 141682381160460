import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMap, MarkerF as Marker } from "@react-google-maps/api";
import { CreatehubMapsProps } from "../../@Types/ComponentsInterface/CreateHubProps";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: 2,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};
const containerStyle = {
  width: "40vw",
  height: "19.5rem",
};
const CreatehubModal: React.FC<CreatehubMapsProps> = ({ latit, lngit }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let center = {
    lat: latit || 28.7136,
    lng: lngit || 77.1294,
  };

  return (
    <div>
      <Link
        sx={{ cursor: "pointer", textDecoration: "none" }}
        onClick={handleOpen}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: "#A4A2A2",
            font: "Rubik",
          }}
        >
          {" "}
          Map
          <MapOutlinedIcon sx={{ color: "#3183FF" }} />
        </Typography>
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between mb-4">
            <div className="flex">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Hub Location
              </Typography>
            </div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
          >
            {latit !== undefined && lngit !== undefined && (
              <Marker position={{ lat: latit, lng: lngit }} />
            )}
          </GoogleMap>
        </Box>
      </Modal>
    </div>
  );
};
export default CreatehubModal;
