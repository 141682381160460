import React, { useEffect, useRef, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";
import GoogleMapApi from "../CreatehubMaps/CreateHubMaps";
import MapAutoComplete from "../CreatehubMaps/FormAddessSearch/AutoCompleteForm";
import { Place } from "../../../@Types/ComponentsInterface/CreateHubProps";
import { CreateHubApi } from "../../../API/CreateHubApi";
import {
  Alert,
  Button,
  InputLabel,
  LinearProgress,
  Snackbar,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ActionModal from "../../Modals/HubListActionModal";
import CreatehubModal from "../../Modals/CreateHubModal";
const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
  font: "Rubik",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
  font: "Rubik",
};
export default function DataTable() {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/Dashboard");
  };
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [totalCount, setTotalCount] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageRange = 5;
  const [hubListData, setHubListData] = useState<any>();
  const [selectedPlace, setSelectedPlace] = useState<Place | undefined>();
  const [hubName, setHubName] = useState<string>();
  const [hubEmail, setHubEmail] = useState<string>();
  const [runningFleets, setRunningFleets] = useState<number>();
  const handlePlaceSelect = (place: Place | null) => {
    setSelectedPlace(place || undefined);
  };
  useEffect(() => {
    setLoading(true);
    CreateHubApi.api
      .createHubList(page + 1, rowsPerPage)
      .then((e) => {
        setLoading(false);
        if (Array.isArray(e.clientHubs) && e.clientHubs?.length > 0) {
          setHubListData(e.clientHubs);
          setTotalCount(e.totalCount);
        } else {
          setShowSnackbar(true);
        }
      })

      .catch((error) => {
        setLoading(false);
        console.error("API call error: ", error);
      });
  }, [page, rowsPerPage]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
  };
  const handleListClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const createHubApi = new CreateHubApi();
    const responsebody = {
      shopName: hubName,
      shopAddr: selectedPlace?.address.trim(),
      shopEmail: hubEmail,
      hubLatitude: selectedPlace?.lat.toString(),
      hubLongitude: selectedPlace?.lng.toString(),
      hubCity: selectedPlace?.city,
      hubState: selectedPlace?.administrative_area_level_1,
      hubPinCode: selectedPlace?.postcode,
      currentFleets: runningFleets,
    };
    try {
      const apiresponse = await createHubApi.addHubRequest(responsebody);
      setShowSuccessSnackbar(true);
      window.location.reload();
    } catch (error: any) {
      setSnackbarMessage(error.response.data.errorCode);
      setShowErrorSnackbar(true);
    }
  };
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  function getStatusColor(status: boolean) {
    if (status === true) {
      return "#F75C3D1F";
    } else {
      return "#00CD5B1F";
    }
  }

  function getStatusTextColor(status: boolean) {
    if (status === true) {
      return "#F75C3D";
    } else {
      return "#00CD5B";
    }
  }

  function getStatusText(status: boolean) {
    if (status === true) {
      return "Inactive";
    } else {
      return "Active";
    }
  }
  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="border-10 bg-gradient-to-r from-slate-100 to-slate-50 pl-6 pr-6"
    >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: "flex",
          alignItems: "center",
        }}
        className="bg-gradient-to-r from-slate-100 to-slate-50"
      >
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "left" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <a onClick={navigateToHome} className="cursor-pointer text-black">
              <ArrowBackIcon className="mr-4 mb-1" />
            </a>
            <b>Create Hub</b>
          </Typography>
        }
        {
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "right", marginRight: "48px" }}
            component="div"
          >
            <HomeIcon className="mb-1" />
            {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "}
            Create Hub
          </Typography>
        }
      </Toolbar>

      <div className="border-0 rounded-xl m-1 bg-white px-12 pt-10">
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="flex w-full mb-8">
            <div className="w-3/4">
              <div className="	flex justify-between mr-4">
                <div className="w-2/4 mr-6">
                  <InputLabel className="text-left mb-2" htmlFor="email">
                    Hub Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    placeholder="Enter Hub Name"
                    className="text-slate-300"
                    onChange={(e) => {
                      setHubName(e.target.value);
                    }}
                    value={hubName}
                  />
                </div>
                <div className="w-2/4">
                  <InputLabel className="text-left	mb-2" htmlFor="email">
                    Select Address
                  </InputLabel>
                  <MapAutoComplete
                    value={selectedPlace}
                    onPlaceSelect={handlePlaceSelect}
                  />
                </div>
              </div>
              <div className="flex justify-between mr-4 mt-6">
                <div className="w-2/4 mr-6">
                  <InputLabel
                    className="text-left mb-2 font-bold"
                    htmlFor="email"
                  >
                    Hub Email ID
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    placeholder="Email ID"
                    className="text-slate-300"
                    onChange={(e) => {
                      const emailValue = e.target.value;

                      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue)) {
                        setHubEmail(emailValue);
                        setErrorMessage("");
                      } else {
                        setErrorMessage("Please enter a valid email address.");
                      }
                    }}
                    value={hubEmail}
                    helperText={errorMessage} 
                    error={Boolean(errorMessage)}
                  />
                </div>
                <div className="w-2/4">
                  <InputLabel
                    className="text-left	mb-2 font-bold"
                    htmlFor="email"
                  >
                    Current Running Fleets
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    placeholder="Petrol & EV's"
                    className="text-slate-300"
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      setRunningFleets(isNaN(value) ? undefined : value);
                    }}
                    value={runningFleets || ""}
                  />
                </div>
              </div>
              <div className="flex justify-end mr-4 mt-6">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    textTransform: "none",
                    bgcolor: "#FF725E",
                    py: 1,
                    px: 10,
                    mt: 2,
                    "&:hover": { bgcolor: "#FF725E95" },
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div>
              {/* {selectedPlace?.lat}
              {selectedPlace?.lng} */}
              <GoogleMapApi
                onPinChange={(changedLocationDetails) => {
                  setSelectedPlace(changedLocationDetails);
                }}
                latit={selectedPlace?.lat}
                lngit={selectedPlace?.lng}
              />
            </div>
          </div>
        </form>
        <Snackbar
          open={showSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity="success"
          >
            HUB CREATED SUCCESSFULLY!
          </Alert>
        </Snackbar>
        <Snackbar
          open={showErrorSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert sx={{ width: "100%" }} onClose={handleClose} severity="error">
            {SnackbarMessage.replace(/_/g, " ")}
          </Alert>
        </Snackbar>
        <hr />
        <Typography
          variant="h5"
          sx={{ textAlign: "left", mt: 2, fontWeight: 400 }}
        >
          Clients Hub
        </Typography>
        {loading ? (
          <LinearProgress
            sx={{
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                      Hubs Name
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 3 }}>
                      Location
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Pin Code
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Map View
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.25 }}>
                      Status
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hubListData?.map((row: any) => (
                    <TableRow sx={{ display: "flex" }} key={row.shopId}>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                        {row.shopName}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 3 }}>
                        {row.shopAddr}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.hubPinCode}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        <CreatehubModal
                          latit={row.hubLatitude}
                          lngit={row.hubLongitude}
                        />
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ flex: 1.25, alignItems: "center" }}
                      >
                        <span
                          style={{
                            backgroundColor: getStatusColor(row.deleted),
                            color: getStatusTextColor(row.deleted),
                            paddingInline: "18px",
                            paddingBlock: "4px",
                            borderRadius: "14px",
                            width: "100px",
                          }}
                        >
                          {getStatusText(row.deleted)}
                        </span>
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        <ActionModal rowData={row} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pl-2 pt-4 pb-2 pr-16">
              <div
                className="pagination"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <select
                  className="pl-2 pr-2 rounded"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                  style={{
                    backgroundColor: "#EBEBEB",
                    fontFamily: "Rubik",
                    fontSize: "16px",
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </select>
                <div>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 0}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginRight: "6px",
                    }}
                  >
                    {"<"}
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= pageCount - 1}
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      marginLeft: "6px",
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
            </div>
            <Snackbar
              open={showSnackbar}
              autoHideDuration={6000}
              onClose={handleListClose}
            >
              <Alert
                sx={{ width: "100%" }}
                onClose={handleListClose}
                severity="error"
              >
                No data found
              </Alert>
            </Snackbar>
          </>
        )}
      </div>
    </div>
  );
}
