import React from "react";
import { GoogleMap, MarkerF as Marker } from "@react-google-maps/api";
import { CreatehubMapsProps } from "../../../@Types/ComponentsInterface/CreateHubProps";

const containerStyle = {
  width: "40vw",
  height: "19.5rem",
};

const CreatehubMaps: React.FC<CreatehubMapsProps> = ({
  latit = 28.7136,
  lngit = 77.1294,
  onPinChange = () => {},
}) => {
  let center = {
    lat: latit,
    lng: lngit,
  };
  const geocoder = new google.maps.Geocoder();

  return (
    <>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <Marker
          position={center}
          opacity={1}
          icon={"/assets/icons/location-pin.png"}
          draggable={true}
          onDragEnd={(e) => {
            if (e.latLng)
              geocoder
                .geocode({
                  location: {
                    lat: e.latLng?.lat(),
                    lng: e.latLng?.lng(),
                  },
                })
                .then((locationDetails) => {
                  if (locationDetails.results.length > 0) {
                    onPinChange({
                      lat: locationDetails.results[0].geometry.location.lat(),
                      lng: locationDetails.results[0].geometry.location.lng(),
                      city:
                        locationDetails.results[0].address_components.find(
                          (e) => e.types.includes("political")
                        )?.long_name || "",
                      address: locationDetails.results[0].formatted_address,
                      administrative_area_level_1:
                        locationDetails.results[0].address_components.find(
                          (e) => e.types.includes("administrative_area_level_1")
                        )?.long_name || "",
                      country:
                        locationDetails.results[0].address_components.find(
                          (e) => e.types.includes("country")
                        )?.long_name || "",
                      postcode:
                        locationDetails.results[0].address_components.find(
                          (e) => e.types.includes("postal_code")
                        )?.long_name || "postal_code",
                      utc_offset: 0,
                    });
                    // console.log(locationDetails);
                  }
                });
          }}
        />
      </GoogleMap>
    </>
  );
};

export default CreatehubMaps;
