import React, { useEffect, useState } from "react";
import { HyperTrackHome } from "../../../API/HyperTrack/HomePage";
import RangeDatePicker from "../../UI/AppDatePicker/AppDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppHeatMap } from "../../../components/Layouts/GoogleMapsHyperTrack/HeatMap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GoogleMapApi from "../../../components/Layouts/GoogleMapsHyperTrack/Maps";
import { useNavigate } from "react-router-dom";
import DashboardFrontCardHyperTrack from "../../../components/Layouts/DashboardFrontCardHyperTrack/DashboardFrontCard";
import {
  Alert,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Link,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { CSVLink } from "react-csv";

const HyperTrackHomepage = () => {
  const csvHeaders = [
    { label: "S.No", key: "index" },
    { label: "Date", key: "Date" },
    { label: "Client ID", key: "Client_ID" },
    { label: "City", key: "city" },
    { label: "Rider Name", key: "Rider_Name" },
    { label: "Client", key: "Client" },
    { label: "User ID", key: "userId" },
    { label: "Cycle ID", key: "cycleId" },
    { label: "QrCode", key: "QrCode" },
    { label: "Ride ID", key: "rideId" },
    { label: "Fleet Name", key: "fleet_name" },
    { label: "IOT Type", key: "IOT_Type" },
    { label: "Start Time", key: "Start_Time" },
    { label: "IOT Distance", key: "iot_distance" },
    { label: "Active Duration", key: "Active_Duration" },
    { label: "Stop Duration", key: "Stop_Duration" },
    { label: "Drive Duration", key: "Drive_Duration" },
    { label: "Average Speed", key: "Average_Speed" },
    { label: "Disconnection Time", key: "Disconnection_time" },
    { label: "Login Hours", key: "Login_Hours" },
    { label: "Total Orders", key: "Total_Orders" },
    { label: "Client Distance", key: "client_distance" },
    { label: "Distance Per Order", key: "Distance_per_order" },
    { label: "Time Per Order", key: "Time_per_order" },
    { label: "Home Lat", key: "home_lat" },
    { label: "Home Long", key: "home_long" },
    { label: "Work Lat", key: "work_lat" },
    { label: "Work Long", key: "work_long" },
  ];
  const allcsvHeaders = [
    { label: "S.No", key: "index" },
    { label: "Date", key: "Date" },
    { label: "Client ID", key: "Client_ID" },
    { label: "City", key: "city" },
    { label: "Rider Name", key: "Rider_Name" },
    { label: "Client", key: "Client" },
    { label: "User ID", key: "userId" },
    { label: "Cycle ID", key: "cycleId" },
    { label: "QrCode", key: "QrCode" },
    { label: "Ride ID", key: "rideId" },
    { label: "Fleet Name", key: "fleet_name" },
    { label: "IOT Type", key: "IOT_Type" },
    { label: "Start Time", key: "Start_Time" },
    { label: "IOT Distance", key: "iot_distance" },
    { label: "Active Duration", key: "Active_Duration" },
    { label: "Stop Duration", key: "Stop_Duration" },
    { label: "Drive Duration", key: "Drive_Duration" },
    { label: "Average Speed", key: "Average_Speed" },
    { label: "Disconnection Time", key: "Disconnection_time" },
    { label: "Login Hours", key: "Login_Hours" },
    { label: "Total Orders", key: "Total_Orders" },
    { label: "Client Distance", key: "client_distance" },
    { label: "Distance Per Order", key: "Distance_per_order" },
    { label: "Time Per Order", key: "Time_per_order" },
    { label: "Home Lat", key: "home_lat" },
    { label: "Home Long", key: "home_long" },
    { label: "Work Lat", key: "work_lat" },
    { label: "Work Long", key: "work_long" },
  ];
  const eautocsvHeaders = [
    { label: "Date", key: "Date" },
    { label: "Client ID", key: "Client_ID" },
    { label: "City", key: "city" },
    { label: "Rider Name", key: "Rider_Name" },
    { label: "Client", key: "Client" },
    { label: "User ID", key: "userId" },
    { label: "Cycle ID", key: "cycleId" },
    { label: "QrCode", key: "QrCode" },
    { label: "Ride ID", key: "rideId" },
    { label: "Fleet Name", key: "fleet_name" },
    { label: "IOT Type", key: "IOT_Type" },
    { label: "Start Time", key: "Start_Time" },
    { label: "IOT Distance", key: "iot_distance" },
    { label: "Active Duration", key: "Active_Duration" },
    { label: "Stop Duration", key: "Stop_Duration" },
    { label: "Drive Duration", key: "Drive_Duration" },
    { label: "Average Speed", key: "Average_Speed" },
    { label: "Disconnection Time", key: "Disconnection_time" },
  ];
  const tableHeaderCellStyle = {
    fontWeight: 600,
    fontSize: "14px",
    color: "#455A64",
    font: "Rubik",
  };
  const tableBodyCellStyle = {
    fontWeight: 400,
    fontSize: "14px",
    color: "#A4A2A2",
    font: "Rubik",
  };
  async function getLocality(
    apiKey: string,
    latitude: number,
    longitude: number
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent) {
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }

  const calculateRelativeDate = (days: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
  };
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [hotspotsLoading, setHotspotsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [hotspotListData, setHotspotListData] = useState<any>();
  const [allhotspotListData, setAllHotspotListData] = useState<any>();
  const [eautohotspotListData, setEAutoHotspotListData] = useState<any>();

  const [startDate, setStartDate] = useState<Date | null>(
    calculateRelativeDate(9)
  );
  const [endDate, setEndDate] = useState<Date | null>(calculateRelativeDate(2));
  const [totalCount, setTotalCount] = useState<any>();
  const [summary, setSummary] = useState<any>();
  const [merchantList, setMerchantList] = useState<any>();
  const [cityList, setCityList] = useState<any>();
  const [city, setCity] = useState<any>();
  const [merchant, setMerchant] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [hotspots, setHotspots] = useState<any>();
  const [addressData, setAddressData] = useState<
    Array<{ location: string | null; totalOrders: number }>
  >([]);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [searchValInput, setSearchValInput] = useState<string | null>(null);

  const fetchHotspotAddresses = async () => {
    const newAddressData: Array<{
      location: string | null;
      totalOrders: number;
    }> = [];

    for (const hotspot of hotspots) {
      try {
        const addresses = await getLocality(
          googleMapApiKey,
          hotspot.Hotspot_Lat,
          hotspot.Hotspot_Long
        );
        const location = addresses !== null ? addresses : "Unknown Address";
        newAddressData.push({ location, totalOrders: hotspot.totalOrders });
      } catch (error) {
        console.error(error);
        newAddressData.push({
          location: "Unknown Address",
          totalOrders: hotspot.totalOrders,
        });
      }
    }

    setAddressData(newAddressData);
  };

  useEffect(() => {
    if (hotspots && hotspots.length > 0) {
      fetchHotspotAddresses();
    }
  }, [hotspots]);
  const pageRange = 5;
  const navigate = useNavigate();

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // Handle date range change

    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };
  const handleSearchClick = () => {
    setSearchVal(searchValInput);
  };
  const handleClearSearch = () => {
    setSearchVal("");
    setSearchValInput("");
  };
  useEffect(() => {
    const apiUrl = `${searchVal ? `search=${searchVal}` : ""}`;
    setLoading(true);
    setSummaryLoading(true);
    setHotspotsLoading(true);
    HyperTrackHome.api
      .hotspotsHypertrack(startDate, endDate, page + 1, merchant, city, apiUrl)
      .then((e) => {
        if (Array.isArray(e.data) && e.data.length > 0) {
          setHotspotListData(e.data);
          setLoading(false);
          if (e && typeof e.records !== "undefined") {
            setTotalCount(e.records);
          }
        } else {
          setShowSnackbar(true);
        }
      });
    HyperTrackHome.api.allhotspotListResponse().then((e) => {
      setAllHotspotListData(e.data);
    });
    HyperTrackHome.api.eautohotspotListResponse().then((e) => {
      setEAutoHotspotListData(e.data);
    });
    HyperTrackHome.api.merchantListHyperTrack().then((e) => {
      setMerchantList(e.merchant_list);
    });
    HyperTrackHome.api.cityListHyperTrack().then((e) => {
      setCityList(e.city_list);
    });
    HyperTrackHome.api
      .hotspotArea(startDate, endDate, merchant, city)
      .then((e) => {
        setHotspots(e);
        setHotspotsLoading(false);
      });
  }, [endDate, page, merchant, city, searchVal]);
  useEffect(() => {
    HyperTrackHome.api
      .summaryRider(startDate, endDate, merchant, city)
      .then((e) => {
        setSummary(e.summary);
        setSummaryLoading(false);
      });
  }, [endDate, merchant, city]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const handlePageChange = (newPage: number) => {
    const newPageNumber = Math.max(1, Math.min(newPage + 1, pageCount));
    setPage(newPageNumber - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = Math.min(pageCount, pageRange);
    const startPage = Math.max(
      0,
      Math.min(page - Math.floor(pageRange / 2), pageCount - pagesToShow)
    );

    return Array.from({ length: pagesToShow }, (_, index) => {
      const pageNumber = startPage + index + 1;
      return (
        <span
          key={index}
          onClick={() => handlePageChange(pageNumber - 1)}
          className={page === pageNumber - 1 ? "active" : "unactive"}
          style={{ marginLeft: "6px" }}
        >
          {pageNumber}
        </span>
      );
    });
  };
  const handleAdd = (uid: {
    cycleId: any;
    userId: any;
    Date: any;
    row: { original: { userId: any; Date: any; cycleId: any } };
  }) => {
    //   setUserId(uid);
    navigate("/zypptracker/ridertrack", {
      state: { userid: uid.userId, date: uid.Date, cycleid: uid.cycleId },
    });
  };
  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  function roundToDecimalPlaces(number: number) {
    const multiplier = 10 ** 2;
    return Math.round(number * multiplier) / multiplier;
  }
  return (
    <div className="flex flex-wrap animate__animated animate__fadeInLeft bg-[#f2f2f2] px-12">
      <div className="flex justify-between w-full mt-4">
        <div className="flex">
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            ZyppTracker
          </Typography>
        </div>
        <div className="flex w-4/5 pl-10">
          <div className="flex items-center w-1/4 mr-6">
            <p className="mr-2 font-semibold">City: </p>
            <FormControl fullWidth>
              <select
                id="city"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                style={{
                  border: 1,
                  borderColor: "#c0baba",
                  borderStyle: "solid",
                  padding: 7,
                  borderRadius: 3,
                }}
              >
                <option value="" selected>
                  All
                </option>
                {cityList?.map((city: string, index: number) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </FormControl>
          </div>
          <div className="flex items-center w-1/3 mr-6">
            <p className="mr-2 font-semibold">Merchant: </p>
            <FormControl fullWidth>
              <select
                id="merchant"
                onChange={(e) => {
                  setMerchant(e.target.value);
                }}
                style={{
                  border: 1,
                  borderColor: "#c0baba",
                  borderStyle: "solid",
                  padding: 7,
                  borderRadius: 3,
                }}
              >
                <option value="" selected>
                  All
                </option>
                {merchantList?.map((merchant: string, index: number) => (
                  <option key={index} value={merchant}>
                    {merchant}
                  </option>
                ))}
              </select>
            </FormControl>
          </div>
          <div className="flex items-center ">
            <p className="mr-2 font-semibold">Date Range: </p>
            <RangeDatePicker
              onChange={handleDateChange}
              maxDaysAdd={-2}
              minDaysSub={92}
              defaultStartDate={9}
              defaultEndDate={-2}
            />
          </div>
        </div>
      </div>

      {summaryLoading ? (
        <Grid className="flex w-full py-4">
          <LinearProgress
            sx={{
              width: "100%",
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00CD5B",
              },
            }}
          />
        </Grid>
      ) : summary ? (
        <div className="flex w-full text-left my-8">
          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#000]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total User Count"
            totalcount={roundToDecimalPlaces(summary?.Total_Count)}
            avgcount={0}
            shorttext=""
            avgtext=""
            imageURL="/assets/hyper-track-icon/distance.svg"
          />

          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Ride Distance"
            totalcount={roundToDecimalPlaces(summary?.Total_Distance)}
            avgcount={roundToDecimalPlaces(summary?.Avg_Distance)}
            shorttext="km"
            avgtext="Avg Distance"
            imageURL="/assets/hyper-track-icon/watch.svg"
          />

          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Drive Duration"
            totalcount={roundToDecimalPlaces(summary?.Drive_Duration)}
            avgcount={roundToDecimalPlaces(summary?.Avg_Drive_Duration)}
            shorttext="hr"
            avgtext="Avg per user:"
            imageURL="/assets/hyper-track-icon/user-time.svg"
          />
          <DashboardFrontCardHyperTrack
            fromColorClass="from-[#0CAF55]"
            toColorClass="to-[#92E3A9]"
            textFirstLine="Total Order"
            shorttext=""
            avgtext="Avg Orders: "
            totalcount={roundToDecimalPlaces(summary?.Total_Orders)}
            avgcount={roundToDecimalPlaces(summary?.Avg_Orders)}
            imageURL="/assets/hyper-track-icon/order.svg"
          />
        </div>
      ) : (
        <div className="flex w-full text-left my-8 px-16">
          <p>Error loading summary data</p>
        </div>
      )}
      <div className="flex justify-between w-full">
        <div className="flex-grow w-1/3	 h-80 rounded-lg bg-[#fff] m-4 shadow">
          <div className="flex justify-between items-center">
            <h2 className="text-center ml-4 mx-1 mt-2 font-bold text-base text-[#2B313F]">
              Area Heatmap
            </h2>
            <div className="flex">
              <IconButton size="small" aria-label="Edit">
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center p-3 animate_animated animate__slideInUp">
            <AppHeatMap
              startDate={startDate}
              endDate={endDate}
              city={city}
              merchant={merchant}
            />
          </div>
        </div>
        <div className="flex-grow w-1/3	 h-80 rounded-lg bg-[#fff] m-4 shadow">
          <div className="flex justify-between items-center">
            <h2 className="text-center ml-4 mx-1 mt-2 mb-3 font-bold text-base text-black">
              Live EV Riders
            </h2>
            <div className="flex">
              <IconButton size="small" aria-label="Edit">
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center px-4 pb-3">
            <div style={{ width: "100%", height: "16.5rem" }}>
              <GoogleMapApi
                startDate={startDate}
                endDate={endDate}
                city={city}
                merchant={merchant}
              />
            </div>
            {/* <img src={"/assets/icons/map.svg"} alt="" className="w-9/12" /> */}
          </div>
        </div>
        <div className="flex-grow w-1/3 h-fit p-2 rounded-lg bg-[#fff] m-4 shadow">
          <div className="flex justify-between items-center">
            <p className="ml-4 mx-1 mt-2 mb-2 font-bold text-base text-black">
              Top 5 Hotspot Areas
            </p>
          </div>
          <div className="px-5">
            {hotspotsLoading ? (
              <LinearProgress
                sx={{
                  backgroundColor: "white",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00CD5B",
                  },
                }}
              />
            ) : addressData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th className="text-left">Location</th>
                    <th className="text-right">Total Orders</th>
                  </tr>
                </thead>
                <tbody>
                  {addressData.map((address, index) => (
                    <tr
                      key={index}
                      className="p-1"
                      style={{
                        borderBottom: 1,
                        borderColor: "#ccc",
                        borderStyle: "solid",
                      }}
                    >
                      <td className="text-left text-sm py-3">
                        {address.location}
                      </td>
                      <td className="text-[#00CD5B] text-right font-bold text-sm">
                        {address.totalOrders}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="font-bold text-[#CE0159] mt-6 mr-6">
                No Data Found
              </p>
            )}
          </div>
        </div>
      </div>

      <>
        <div className="w-full">
          <div className="flex w-full justify-between mt-5">
            <div className="flex">
              <TextField
                placeholder="Search  QR Code"
                id="outlined-basic"
                variant="outlined"
                onChange={(search: any) =>
                  setSearchValInput(search.target.value)
                }
                value={searchValInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleClearSearch}>
                      <ClearIcon style={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                  style: { height: "40px" },
                }}
                // onKeyDown={handleKeyPress}
              />
              <Button
                sx={{
                  bgcolor: "#FF725E33",
                  color: "black",
                  ml: 2,
                  mr: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#FF725E16" },
                }}
                onClick={handleSearchClick}
              >
                Search
              </Button>
            </div>
            <div className="flex mr-4">
              <CSVLink
                data={eautohotspotListData || []}
                headers={eautocsvHeaders}
                filename={`3w_rider_data.csv`}
                style={{ marginRight: "10px" }}
              >
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  3w Data Download CSV
                </Button>
              </CSVLink>
              <CSVLink
                data={hotspotListData || []}
                headers={csvHeaders}
                filename={`view_rider_data.csv`}
                style={{ marginRight: "10px" }}
              >
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  View Data Download CSV
                </Button>
              </CSVLink>
              <CSVLink
                data={allhotspotListData || []}
                headers={allcsvHeaders}
                filename={`all_rider_data.csv`}
              >
                <Button
                  style={{
                    backgroundColor: "#00CD5B",
                    padding: "8px 8px",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  All Data Download CSV
                </Button>
              </CSVLink>
            </div>
          </div>
          {loading ? (
            <LinearProgress
              className="pl-6 pr-6"
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00CD5B",
                },
                width: "100%",
              }}
            />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 0.5 }}>
                      S.No
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      User Id
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                      Rider Name
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 2 }}>
                      Qr Code
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Fleet Name
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Distance
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.25 }}>
                      Active Duration
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1.25 }}>
                      Total Orders
                    </TableCell>
                    <TableCell style={tableHeaderCellStyle} sx={{ flex: 1 }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hotspotListData?.map((row: any) => (
                    <TableRow sx={{ display: "flex" }} key={row.index}>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 0.5 }}>
                        {row.index}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.userId}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                        {row.Rider_Name}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 2 }}>
                        {row.QrCode}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        {row.fleet_name}
                      </TableCell>
                      <TableCell
                        style={tableBodyCellStyle}
                        sx={{ flex: 1, alignItems: "center" }}
                      >
                        {row.iot_distance}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1.25 }}>
                        {row.Active_Duration}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1.25 }}>
                        {row.Total_Orders}
                      </TableCell>
                      <TableCell style={tableBodyCellStyle} sx={{ flex: 1 }}>
                        <Stack direction="row" spacing={0}>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAdd(row);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#00CD5B" }}
                            >
                              <MapIcon />
                            </Typography>
                          </Link>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div className="pl-2 pt-4 pb-2 pr-16">
            <div
              className="pagination"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 0}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginRight: "6px",
                  }}
                >
                  {"<"}
                </button>
                {renderPageNumbers()}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= pageCount - 1}
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    marginLeft: "6px",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Snackbar open={showSnackbar} autoHideDuration={6000}>
          <Alert sx={{ width: "100%" }} severity="error">
            No data found
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};

export default HyperTrackHomepage;
