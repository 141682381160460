import React, { useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import MailOutline from "@mui/icons-material/MailOutline";
import Password from "@mui/icons-material/LockOutlined";
import { ClientApi } from "../../../API/ClientApi";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";

const AuthLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleLogin();
  };
  const tagManagerArgs = {
    gtmId: "G-NWTLSSYVR2",
  };
  TagManager.initialize(tagManagerArgs);
  const handleLogin = async () => {
    const clientApi = new ClientApi();
    setLoader(true);
    try {
      const response = await clientApi.onlineUserAuthentication(
        username,
        password
      );
      if (response.data && response.data.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem(
          "merchantName",
          response.data.userInfo.merchantName
        );
        localStorage.setItem(
          "merchantLogoUrl",
          response.data.userInfo.merchantLogoUrl
        );
        navigate("/Dashboard");
        setLoginError(null);
      } else {
        setLoader(false);
        alert("Invalid Credentials");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
      alert("Invalid Credentials");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} className="w-full flex justify-between">
          <Grid item xs={12}>
            <p className="text-left	font-bold text-3xl text-gray-500">
              Welcome!
            </p>
            <p className="text-left	mb-8 mt-2 text-gray-500">
              Log In to your account
            </p>
            <Stack spacing={1}>
              <InputLabel className="text-left	" htmlFor="email">
                Enter Username
              </InputLabel>
              <TextField
                fullWidth
                id="emailInstant"
                name="emailInstant"
                placeholder="Enter email address"
                className="text-slate-300"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="text-slate-300">
                      <MailOutline />
                    </InputAdornment>
                  ),
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel className="text-left" htmlFor="email">
                Enter Password
              </InputLabel>
              <TextField
                fullWidth
                id="passwordInstant"
                name="passwordInstant"
                placeholder="Enter your password"
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Password />
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} className="flex justify-end pt-0">
            <Typography variant="subtitle1">
              <a
                className="no-underline hover:underline text-green-500 font-bold text-sm"
                href="/forgotpassword"
              >
                Forgot Password?
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loader ? (
              <Button
                sx={{
                  backgroundColor: "#00CD5B",
                  "&:hover": {
                    backgroundColor: "#63dd99",
                  },
                }}
                className="bg-green-500 hover:bg-green-400"
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                <CircularProgress sx={{ color: "white" }} />
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: "#00CD5B",
                  "&:hover": {
                    backgroundColor: "#63dd99",
                  },
                }}
                className="bg-green-500 hover:bg-green-400"
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                Login
              </Button>
            )}
          </Grid>
          {loginError && (
            <Grid item xs={12}>
              <Typography variant="body1" color="error">
                {loginError}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default AuthLogin;
